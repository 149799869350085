import React, { FormEvent, useEffect, useState } from 'react'
import NetService from '../../services/NetService'
import CrudTable from '../../components/CrudTable'
import { Alert, Button, Card, Dialog, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Snackbar, Switch, TextField } from '@mui/material'
import SnackbarData from '../../common/models/SnackbarData'
import ScooterControls from '../../common/models/ScooterControls'
import { MRT_ColumnDef } from 'material-react-table'
import { Info } from '@mui/icons-material'
import Scooter from '../../common/models/Scooter'
import ScooterDetail from '../Scooters/ScooterDetail'
import { getLocalizedStrings } from '../../App'

export default function ScooterConfig() {

    const [scooters, setScooters] = useState<Scooter[]>([]);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [editModalData, setEditModalData] = useState<ScooterControls>(new ScooterControls());
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [detailModal, setDetailModal] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<Scooter>(new Scooter());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [createData, setCreateData] = useState<ScooterControls>(new ScooterControls());
    const strings = getLocalizedStrings();

    const detailStyle: React.CSSProperties = {
        height: '100vh',
        width: '40vw',
        maxWidth: 'none',
        margin: 0,
        backgroundColor: '#000f',
        borderRadius: 0,
        position: 'fixed',
        top: 0,
        right: detailModal ? 0 : '-40vw',
        transition: 'right 0.3s ease-out',
        outline: 'none',
    };

    useEffect(() => {

        NetService.AGet("/scooters")
            .then(resp => setScooters(resp.data.data.Scooters));

    }, []);

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        (child !== undefined) ? (
            setCreateData({
                ...createData,
                [parent]: {
                    ...createData[parent as keyof typeof createData] as {},
                    [child]: value
                }
            })
        ) : (
            setCreateData({ ...createData, [parent]: value })
        )
    }

    function handleEditChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        (child !== undefined) ? (
            setEditModalData({
                ...editModalData,
                [parent]: {
                    ...editModalData[parent as keyof typeof editModalData] as {},
                    [child]: value
                }
            })
        ) : (
            setEditModalData({ ...editModalData, [parent]: value })
        )
    }

    function handleEditClick(value: ScooterControls) {

        setEditModalData(value);
        setEditModal(true);
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APost(`/scooter-controls`, createData);
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 200) {
                    setEditModal(false);
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
        }
    }

    function handleEditSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APut(`/scooter-controls/${editModalData.id}`, editModalData);
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 200) {
                    setEditModal(false);
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
        }
    }

    const overriteColumns = React.useMemo<MRT_ColumnDef<ScooterControls>[]>(
        () => [
            {
                accessorFn: (o) => (
                    <IconButton onClick={() => {
                        setDetailData(o.scooter);
                        setDetailModal(true);
                    }}>
                        
                        <Info />
                    </IconButton>
                    
                ),
                accessorKey: "scooter",
                header: strings.scooterControls.scooter
            },
            {
                accessorKey: "scooter.name",
                header: strings.scooterControls.scooterName
            }
        ],
        []
    );

    return (

        <div>
            <CrudTable overriteColumns={overriteColumns} onClick={handleEditClick} enableDelete endpoint='/scooter-controls' dataType={ScooterControls} dataInstance={new ScooterControls()} enableFilters={false} renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.create}</Button>
                    </div>
                )
            }} columns={[]} data={[]} />

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.scooterControls.create}</a>

                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >
                            <Grid item xs={4}>
                                <TextField type='number' label={strings.scooterControls.tokensVersion} required fullWidth value={editModalData.tokensVersion} name='tokensVersion' onChange={handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField type='number' label={strings.scooterControls.firmwareVersion} required fullWidth value={editModalData.firmwareVersion} name='firmwareVersion' onChange={handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.scooterControls.scooter}</InputLabel>
                                    <Select label={strings.scooterControls.scooter} value={createData.scooterId} name='scooterId' onChange={handleChange} >
                                        {scooters.map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField label={strings.scooterControls.firmwareDownloadLink} required fullWidth value={editModalData.firmwareDownloadLink} name='firmwareDownloadLink' onChange={handleChange} />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControlLabel control={<Switch checked={editModalData.stopVehicle} onChange={() => setEditModalData({ ...editModalData, stopVehicle: !editModalData.stopVehicle })} />} label={strings.scooterControls.stopVehicle} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Switch checked={editModalData.emergencyOpening} onChange={() => setEditModalData({ ...editModalData, emergencyOpening: !editModalData.emergencyOpening })} />} label={strings.scooterControls.emergencyOpening} />
                            </Grid>
                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.create}</Button>

                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={editModal} onClose={() => setEditModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.scooterControls.edit}</a>

                    <form onSubmit={handleEditSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >
                            <Grid item xs={6}>
                                <TextField type='number' label={strings.scooterControls.tokensVersion} required fullWidth value={editModalData.tokensVersion} name='tokensVersion' onChange={handleEditChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type='number' label={strings.scooterControls.parkBreakCurrent} required fullWidth value={editModalData.parkBreakCurrent} name='parkBreakCurrent' onChange={handleEditChange} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField type='number' label={strings.scooterControls.firmwareVersion} required fullWidth value={editModalData.firmwareVersion} name='firmwareVersion' onChange={handleEditChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label={strings.scooterControls.firmwareDownloadLink} required fullWidth value={editModalData.firmwareDownloadLink} name='firmwareDownloadLink' onChange={handleEditChange} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControlLabel control={<Switch checked={editModalData.stopVehicle} onChange={() => setEditModalData({ ...editModalData, stopVehicle: !editModalData.stopVehicle })} />} label={strings.scooterControls.stopVehicle} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Switch checked={editModalData.emergencyOpening} onChange={() => setEditModalData({ ...editModalData, emergencyOpening: !editModalData.emergencyOpening })} />} label={strings.scooterControls.emergencyOpening} />
                            </Grid>
                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Dialog open={detailModal} onClose={() => setDetailModal(false)} PaperProps={{ style: detailStyle }}  >
                <ScooterDetail scooter={detailData} onClose={() => setDetailModal(false)} />
            </Dialog>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};