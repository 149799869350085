import React, { FormEvent, useState } from 'react';
import Company from '../../common/models/Company';
import { Autocomplete, Button, Card, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import NetService from '../../services/NetService';
import Scooter from '../../common/models/Scooter';
import { ScooterFilter } from '../../common/enums/ScooterFilter';
import dayjs from 'dayjs';
import { getLocalizedStrings } from '../../App';


export default function CompaniesDetail({ company, onClose }: { company: Company, onClose?: () => void }) {

    const [data, setData] = useState<Company>(company);
    const [scooters, setScooters] = useState<Scooter[]>();
    const [filter, setFilter] = useState<ScooterFilter>(ScooterFilter.All);
    const strings = getLocalizedStrings();


    React.useEffect(() => {

        NetService.AGet(`/scooters?companyId=${company.id}`)
            .then(o => setScooters(o.data.data.Scooters));

    }, [])


    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setData({
                ...data,
                [parent]: {
                    ...data[parent as keyof typeof data] as {},
                    [child]: value
                }
            })
        ) : (
            setData({ ...data, [parent]: value })
        )
    }

    function handleCompanyEdit(e: FormEvent) {
        e.preventDefault();

        NetService.APut(`/companies/${data.id}`, data)
            .then(resp => {

            });
    }

    return (
        <Card style={{ padding: "20px", flex: 1 }}>
            <Grid container >
                <Grid item xs={11} >
                    <a style={{ fontSize: 35 }} >{strings.company.details}</a>
                </Grid>
                <Grid item xs={1} >
                    <IconButton onClick={() => { if (onClose !== undefined) onClose(); }} >
                        <Close fontSize='large' />
                    </IconButton>
                </Grid>
            </Grid>

            <Divider style={{ margin: "5px 0px" }} />

            <form onSubmit={handleCompanyEdit} style={{ margin: "20px 0px 0px 0px" }}>
                <Grid container spacing={3} >
                    <Grid item xs={4}>
                        <TextField disabled type='text' required label={strings.company.name} name='name' fullWidth value={data.name} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField type='email' required label={strings.company.email} name='email' fullWidth value={data.email} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={5}>
                        <TextField type='text' required label={strings.company.address} name='address' fullWidth value={data.address} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={7}>
                        <TextField type='email' required label={strings.company.contactPersonEmail} name='contactPersonEmail' fullWidth value={data.contactPersonEmail} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField type='tel' required label={strings.company.contactPersonPhoneNumber} name='contactPersonPhoneNumber' fullWidth value={data.contactPersonPhoneNumber} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField disabled type='text' required label={strings.company.gisaNumber} name='gisaNumber' fullWidth value={data.gisaNumber} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField disabled type='text' required label={strings.company.uid} name='uid' fullWidth value={data.uid} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete disabled multiple defaultValue={data.roles} options={data.roles} getOptionLabel={(option) => option.roleName} renderInput={(params) => <TextField {...params} label={strings.company.roles} />} />
                    </Grid>

                    {(scooters !== undefined && scooters.length > 0) &&
                        <Grid item xs={3}>
                            <FormControl size='small' fullWidth style={{ margin: "10px 0px -20px 0px" }} >
                                <InputLabel>{strings.company.filter}</InputLabel>
                                <Select value={filter} label={strings.company.filter} onChange={(e) => setFilter(Number(e.target.value))} >
                                    {Object.keys(ScooterFilter).filter(o => !isNaN(Number(o))).map(o => {
                                        return <MenuItem value={o}>{ScooterFilter[Number(o)]}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={12} >
                        {(scooters !== undefined && scooters.length > 0) ?
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{strings.scooter.model}</TableCell>
                                        <TableCell>{strings.scooter.currentLocation}</TableCell>
                                        <TableCell>{strings.scooter.region}</TableCell>
                                        <TableCell>{strings.scooter.startToEnd}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(filter === ScooterFilter.All) ?
                                        scooters?.map(s =>
                                            s.scooterReservations.map(r => {
                                                return <TableRow key={s.id} >
                                                    <TableCell>{s.model}</TableCell>
                                                    <TableCell>{s.currentLocation.address}</TableCell>
                                                    <TableCell>{s.region.regionName}</TableCell>
                                                    <TableCell>{dayjs(r.reservation.startDateTime).format("DD.MM.YYYY hh:mm") + " - " + dayjs(r.reservation.endDateTime).format("DD.MM.YYYY hh:mm")}</TableCell>
                                                </TableRow>
                                            })
                                        ) : (filter === ScooterFilter.CurrentActive) ?
                                            scooters?.map(s =>
                                                s.scooterReservations.filter(o => dayjs(o.reservation.startDateTime) < dayjs() && dayjs(o.reservation.endDateTime) > dayjs()).map(r => {
                                                    return <TableRow key={s.id} >
                                                        <TableCell>{s.model}</TableCell>
                                                        <TableCell>{s.currentLocation.address}</TableCell>
                                                        <TableCell>{s.region.regionName}</TableCell>
                                                        <TableCell>{dayjs(r.reservation.startDateTime).format("DD.MM.YYYY hh:mm") + " - " + dayjs(r.reservation.endDateTime).format("DD.MM.YYYY hh:mm")}</TableCell>
                                                    </TableRow>
                                                })
                                            ) :
                                            scooters?.map(s =>
                                                s.scooterReservations.filter(o => dayjs(o.reservation.endDateTime) < dayjs()).map(r => {
                                                    return <TableRow key={s.id} >
                                                        <TableCell>{s.model}</TableCell>
                                                        <TableCell>{s.currentLocation.address}</TableCell>
                                                        <TableCell>{s.region.regionName}</TableCell>
                                                        <TableCell>{dayjs(r.reservation.startDateTime).format("DD.MM.YYYY hh:mm") + " - " + dayjs(r.reservation.endDateTime).format("DD.MM.YYYY hh:mm")}</TableCell>
                                                    </TableRow>
                                                })
                                            )}
                                </TableBody>
                            </Table>
                            :
                            <b>{strings.company.hasRentedScooters}</b>
                        }
                    </Grid>

                </Grid>


                <Button style={{ margin: "20px 0px 0px 0px" }} type='submit' color='success' variant='contained' >
                    {strings.save}
                </Button>

            </form>
        </Card>
    );
};
