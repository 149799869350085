import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Card, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Scooter from '../../common/models/Scooter';
import { BatteryStatus } from '../../common/enums/BatteryStatus';
import NetService from '../../services/NetService';
import Region from '../../common/models/Region';
import Hub from '../../common/models/Hub';
import { ScooterStatus } from '../../common/enums/ScooterStatus';
import { getLocalizedStrings } from '../../App';

class ScooterUpdate {

    constructor(status: ScooterStatus, regionId: number, hubId: number, latitude: number, longitude: number) {
        this.status = status;
        this.regionId = regionId;
        this.hubId = hubId;
        this.latitude = latitude;
        this.longitude = longitude;
    }

    status!: ScooterStatus;
    regionId!: number;
    hubId!: number;
    latitude!: number;
    longitude!: number;
}


export default function ScooterDetail({ scooter, editEnabled, onClose }: { scooter: Scooter, editEnabled?: boolean, onClose?: () => void }) {

    const [data, setData] = useState<Scooter>(scooter);
    const [regions, setRegions] = useState<Region[]>([]);
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [errorText, setErrorText] = useState<string>();
    const strings = getLocalizedStrings();


    useEffect(() => {

        if (editEnabled) {
            NetService.AGet("/regions")
                .then(o => setRegions(o.data.data.Regions));

            NetService.AGet(`/hubs?regionIds=${data.region.id}`)
                .then(o => setHubs(o.data.data.Hubs));
        }

    }, []);

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setData({
                ...data,
                [parent]: {
                    ...data[parent as keyof typeof data] as {},
                    [child]: value
                }
            })
        ) : (
            setData({ ...data, [parent]: value })
        )
    }

    function handleRegionChange(e: { target: { name: any; value: any; }; }) {

        const region: Region = regions.find(f => f.id === Number(e.target.value)) ?? new Region();

        NetService.AGet(`/hubs?regionIds=${region.id}`)
            .then(o => {
                if (o.status === 200) {
                    setHubs(o.data.data.Hubs)
                    if (o.data.data.Hubs[0] !== undefined) {
                        setData({ ...data, region: region, hub: o.data.data.Hubs[0] });
                    }
                    else {
                        setData({ ...data, region: region });
                    }
                }
            });
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APut(`/scooters/${data.id}`, new ScooterUpdate(data.status, data.region.id, data.hub.id, Number(data.currentLocation.latitude), Number(data.currentLocation.longitude)))
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorText(resp.data.message);
                }
            });
    }

    return (
        <Card style={{ padding: "20px", flex: 1, overflow: "auto" }}>
            <Grid container >
                <Grid item xs={11} >
                    <a style={{ fontSize: 35 }} >{strings.scooter.details}</a>
                </Grid>
                <Grid item xs={1} >
                    <IconButton onClick={() => { if (onClose !== undefined) onClose(); }} >
                        <Close fontSize='large' />
                    </IconButton>
                </Grid>
            </Grid>

            <Divider style={{ margin: "5px 0px" }} />

            <form onSubmit={handleSubmit} style={{ margin: "20px 0px 0px 0px" }}>
                <Grid container spacing={3} >
                    <Grid item xs={7}>
                        <TextField disabled type='text' required label={strings.scooter.model} name='model' fullWidth value={data.model} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField disabled type='number' required label={strings.scooter.serialNumber} name='serialNumber' fullWidth value={data.serialNumber} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl disabled required fullWidth>
                            <InputLabel>{strings.scooter.batteryStatus}</InputLabel>
                            <Select value={data.batteryStatus} label={strings.scooter.batteryStatus} name='batteryStatus' onChange={handleChange} >
                                {Object.keys(BatteryStatus).filter(o => !isNaN(Number(o))).map(o => {
                                    return <MenuItem value={BatteryStatus[Number(o)]}>{BatteryStatus[Number(o)]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField disabled type='number' required label={strings.scooter.batteryPercentage} name='batteryPercentage' fullWidth value={data.batteryPercentage} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField disabled type='number' required label={strings.scooter.dailyMileage} name='dailyMileage' fullWidth value={data.dailyMileage} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField disabled type='number' required label={strings.scooter.totalMileage} name='totalMileage' fullWidth value={data.totalMileage} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl disabled={!editEnabled} required fullWidth>
                            <InputLabel>{strings.scooter.status}</InputLabel>
                            <Select value={data.status} onChange={(e) => setData({ ...data, status: e.target.value as ScooterStatus })} >
                                {Object.keys(ScooterStatus).filter(o => !isNaN(Number(o))).map(o => {
                                    return <MenuItem value={ScooterStatus[Number(o)]}>{ScooterStatus[Number(o)]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl disabled={!editEnabled} required fullWidth>
                            <InputLabel>{strings.scooter.region}</InputLabel>
                            <Select value={data.region.id} onChange={handleRegionChange} >
                                {regions.map(o => {
                                    return <MenuItem value={o.id}>{o.regionName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl disabled={!editEnabled} required fullWidth>
                            <InputLabel>{strings.scooter.hub}</InputLabel>
                            <Select value={data.hub.id} onChange={(e) => setData({ ...data, hub: hubs.find(f => f.id === Number(e.target.value)) ?? new Hub() })} >
                                {hubs.map(o => {
                                    return <MenuItem value={o.id}>{o.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography style={{ margin: "5px 0px -10px 0px" }} >{strings.scooter.currentLocation}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Card style={{ padding: "10px" }} >
                            <Grid container spacing={3}>
                                <Grid item xs={6} >
                                    <TextField type='number' required label={strings.location.latitude} name='currentLocation.latitude' fullWidth value={data.currentLocation.latitude} onChange={handleChange} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField type='number' required label={strings.location.longitude} name='currentLocation.longitude' fullWidth value={data.currentLocation.longitude} onChange={handleChange} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField disabled type='text' required label={strings.location.address} name='currentLocation.address' fullWidth value={data.currentLocation.address} onChange={handleChange} />
                                </Grid>

                                <Grid item xs={3} >
                                    <TextField disabled type='text' required label={strings.location.city} name='currentLocation.city' fullWidth value={data.currentLocation.city} onChange={handleChange} />
                                </Grid>

                                <Grid item xs={3} >
                                    <TextField disabled type='text' required label={strings.location.country} name='currentLocation.country' fullWidth value={data.currentLocation.country} onChange={handleChange} />
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField disabled type='text' required label={strings.location.displayAddress} name='currentLocation.displayAddress' fullWidth value={data.currentLocation.displayAddress} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button style={{ margin: "20px 0px 0px 0px" }} type='submit' color='success' variant='contained' >
                            {strings.save}
                        </Button>
                    </Grid>

                    <Grid item xs={10} >
                        <a style={{ color: "red" }}>
                            {errorText}
                        </a>
                    </Grid>
                </Grid>

            </form>
        </Card>
    );
};
