
export enum BatteryStatus {
    FULL, // The battery is fully charged.
    CHARGING, // The battery is currently being charged.
    DISCHARGING, // The battery is being used and is gradually losing charge.
    LOW, // The battery level has dropped below a certain threshold, indicating low charge.
    CRITICAL, // The battery level has reached a critically low point, requiring immediate attention or action.
    EMPTY, // The battery is completely depleted and requires recharging.
    UNKNOWN, // The battery status cannot be determined or is not available.
    FAULTY, // The battery is defective or malfunctioning.
}