import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControlLabel, Grid, IconButton, Paper, Stack, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { JSX } from 'react/jsx-runtime';
import { ArrowDropDown, BatteryChargingFull, Close, ForkRight, Home, Info, LocationCity, LocationOn, Numbers } from '@mui/icons-material';
import Scooter from '../../common/models/Scooter';
import ScooterControls from '../../common/models/ScooterControls';
import { getLocalizedStrings } from '../../App';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import DateTimeRangePicker from '../../components/DateTimeRangePicker';

type DetailProps = JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement> & {

    scooter: Scooter;
    scooterControl: ScooterControls | undefined;
    onClose: () => void;
    onRouteUpdate: (start: Dayjs, end: Dayjs, scooter: Scooter) => void;
};

type RouteData = {
    start: Dayjs | undefined;
    end: Dayjs | undefined;
}


export default function MapScooterDetail(props: DetailProps) {

    const [routeData, setRouteData] = useState<RouteData>({ start: dayjs().subtract(1, "day"), end: dayjs() });
    const [activeTab, setActiveTab] = useState<number>(0);
    const strings = getLocalizedStrings();


    function handleTabChange(event: React.SyntheticEvent, newValue: number) {
        setActiveTab(newValue);
    };

    function handleRouteUpdate(start: Dayjs | undefined, end: Dayjs | undefined) {

        setRouteData({ start: start, end: end });
        if (start !== undefined && end !== undefined) {
            props.onRouteUpdate(start, end, props.scooter);
        }
    }

    return (
        <div {...props} >
            <Paper style={{ padding: "10px", height: "85vh", width: "20vw", overflow: "auto" }} >

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Stack direction="row" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <Tabs value={activeTab} onChange={handleTabChange} >
                            <Tab label="Info" value={0} />
                            {props.scooterControl !== undefined &&
                                <Tab label="Settings" value={1} />
                            }
                        </Tabs>

                        <IconButton onClick={props.onClose} >
                            <Close />
                        </IconButton>
                    </Stack>
                </Box>

                <Box sx={{ margin: "10px" }}>
                    {activeTab === 0 && props.scooter.currentLocation !== undefined &&
                        <Box>
                            <Accordion style={{ boxShadow: "none" }} defaultExpanded>
                                <AccordionSummary expandIcon={<ArrowDropDown />}>
                                    <Typography>Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.name}>
                                                    <Info />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.name}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.model}>
                                                    <Info />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.model}</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.serialNumber}>
                                                    <Numbers />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.serialNumber}</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.hub}>
                                                    <Home />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.hub.name}</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.region}>
                                                    <LocationCity />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.hub.region.regionName}</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack direction="row">
                                                <Typography>¹</Typography>
                                                <Tooltip placement='top' title={strings.scooterDetail.info.batteryOne}>
                                                    <BatteryChargingFull />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.batteryPercentage}%</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack direction="row">
                                                <Typography>²</Typography>
                                                <Tooltip placement='top' title={strings.scooterDetail.info.batteryTwo}>
                                                    <BatteryChargingFull />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.secondBatteryPercentage}%</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.dailyMileage}>
                                                    <ForkRight />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.dailyMileage} km</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.totalMileage}>
                                                    <ForkRight />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.totalMileage} km</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.location}>
                                                    <LocationOn />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.currentLocation.address}</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <Tooltip placement='top' title={strings.scooterDetail.info.city}>
                                                    <LocationCity />
                                                </Tooltip>
                                                <Typography sx={{ margin: "0px 10px" }}>{props.scooter.currentLocation.city}</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{ boxShadow: "none" }}>
                                <AccordionSummary expandIcon={<ArrowDropDown />}>
                                    <Typography>Route</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DateTimeRangePicker value={routeData} onDateTimeChanged={handleRouteUpdate} />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    }
                    {activeTab === 1 && props.scooterControl !== undefined &&
                        <Box>
                            <Accordion style={{ boxShadow: "none" }} defaultExpanded>
                                <AccordionSummary expandIcon={<ArrowDropDown />}>
                                    <Typography>Settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} >
                                            <TextField disabled type='number' fullWidth label={strings.scooterDetail.settings.tokensVersion} value={props.scooterControl.tokensVersion} />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField disabled type='number' fullWidth label={strings.scooterDetail.settings.firmwareVersion} value={props.scooterControl.firmwareVersion} />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <TextField disabled fullWidth label={strings.scooterDetail.settings.firmwareDownloadLink} value={props.scooterControl.firmwareDownloadLink} />
                                        </Grid>

                                        <Grid item xs={7} >
                                            <FormControlLabel disabled label={strings.scooterDetail.settings.stopVehicle} control={<Checkbox value={props.scooterControl.stopVehicle} checked={props.scooterControl.stopVehicle} />} />
                                        </Grid>
                                        <Grid item xs={5} >
                                            <FormControlLabel disabled label={strings.scooterDetail.settings.emergencyOpening} control={<Checkbox value={props.scooterControl.emergencyOpening} checked={props.scooterControl.emergencyOpening} />} />
                                        </Grid>

                                        <Grid item xs={6} >
                                            <TextField disabled type='number' fullWidth label={strings.scooterDetail.settings.parkBreakCurrent} value={props.scooterControl.parkBreakCurrent} />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField disabled type='number' fullWidth label={strings.scooterDetail.settings.twoBatteryMode} value={props.scooterControl.twoBatteryMode} />
                                        </Grid>

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Box>
                    }
                </Box>

            </Paper>
        </div>
    );
};