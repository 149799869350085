import Authority from "./Authority";
import Role from "./Role";
import 'reflect-metadata'

@Reflect.metadata("name", "Company")
export default class Company {
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", String)
    name!: string;
    @Reflect.metadata("type", String)
    email!: string;
    @Reflect.metadata("type", Role)
    roles!: Role[];
    @Reflect.metadata("type", String)
    entityStatus!: string;
    @Reflect.metadata("type", Number)
    companyId!: number;
    @Reflect.metadata("type", String)
    address!: string;
    @Reflect.metadata("type", String)
    contactPersonEmail!: string;
    @Reflect.metadata("type", String)
    contactPersonPhoneNumber!: string;
    @Reflect.metadata("type", String)
    gisaNumber!: string;
    @Reflect.metadata("type", String)
    uid!: string;
    @Reflect.metadata("type", Boolean)
    enabled!: boolean;
    @Reflect.metadata("type", String)
    username!: string;
    @Reflect.metadata("type", Authority)
    authorities!: Authority[];
    @Reflect.metadata("type", Boolean)
    accountNonExpired!: boolean;
    @Reflect.metadata("type", Boolean)
    accountNonLocked!: boolean;
    @Reflect.metadata("type", Boolean)
    credentialsNonExpired!: boolean;
}