
export default class CompanyRegister {
    name: string = "";
    email: string = "";
    password: string = "";
    oneManCompany: boolean = false;
    address: string = "";
    contactPersonEmail: string = "";
    contactPersonPhoneNumber: string = "";
    gisaNumber: string = "";
    uid: string = "";
}