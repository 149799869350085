import React, { FormEvent, useEffect, useState } from 'react'
import NetService from '../../services/NetService'
import Company from '../../common/models/Company'
import CrudTable from '../../components/CrudTable'
import { Alert, Button, Card, Chip, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Snackbar, Stack, TextField, Typography } from '@mui/material'
import Reservation, { AssignReservation, ReservationCreation, ReservationUpdate } from '../../common/models/Reservation'
import Region from '../../common/models/Region'
import dayjs, { Dayjs } from 'dayjs'
import CompaniesDetail from '../Companies/CompaniesDetail'
import { MRT_ColumnDef } from 'material-react-table'
import { Info } from '@mui/icons-material'
import SnackbarData from '../../common/models/SnackbarData'
import Hub from '../../common/models/Hub'
import Scooter from '../../common/models/Scooter'
import Driver from '../../common/models/Driver'
import { EntityStatus } from '../../common/enums/EntityStatus'
import AuthService from '../../services/AuthService'
import { Roles } from '../../common/enums/Roles'
import { getLocalizedStrings } from '../../App'
import DateTimeRangePicker from '../../components/DateTimeRangePicker'

type LatestReservationData = {
    reservationId: number;
    scooterId: number;
}

type DriverAssign = {
    driverId: number;
    scooterId: number;
}

type ReservationDriverAssign = {
    reservationId: number;
    assignments: DriverAssign[];
}

type AvailabilityData = {
    date: Dayjs;
    numberOfAvailableScooters: number;
}

export default function ReservationsPage() {

    const [reservations, setReservations] = useState<AssignReservation[]>([]);
    const [scooters, setScooters] = useState<Scooter[]>([]);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [regions, setRegions] = useState<Region[]>();
    const [hubs, setHubs] = useState<Hub[]>();
    const [modalData, setModalData] = useState<ReservationCreation>(new ReservationCreation());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [editData, setEditData] = useState<ReservationUpdate>(new ReservationUpdate());
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [successSnackbar, setSuccessSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [detailModal, setDetailModal] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<Company>(new Company());
    const [assignDriverModal, setAssignDriverModal] = useState<boolean>(false);
    const [latestReservationData, setLatestReservationData] = useState<LatestReservationData[]>([]);
    const [reservationDriverAssignments, setReservationDriverAssignments] = useState<ReservationDriverAssign>({ reservationId: 0, assignments: [] });
    const [availabilityData, setAvailabilityData] = useState<AvailabilityData[]>([]);
    const [isReservationValid, setIsReservationValid] = useState<boolean>(true);

    const strings = getLocalizedStrings();

    const detailStyle: React.CSSProperties = {
        height: '100vh',
        width: '40vw',
        maxWidth: 'none',
        margin: 0,
        backgroundColor: '#000f',
        borderRadius: 0,
        position: 'fixed',
        top: 0,
        right: detailModal ? 0 : '-40vw',
        transition: 'right 0.3s ease-out',
        outline: 'none',
    };



    useEffect(() => {

        NetService.AGet("/companies")
            .then(resp => {
                if (resp.status === 200) setCompanies(resp.data.data.Companies);
                setModalData({ ...modalData, companyId: Number(AuthService.GetCompanyId()) });
            });

        NetService.AGet("/regions")
            .then(o => setRegions(o.data.data.Regions));

        NetService.AGet("/scooters")
            .then(o => setScooters(o.data.data.Scooters));

        NetService.AGet("/reservations")
            .then(o => setReservations(o.data.data.reservations));
    }, [])

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if (!isReservationValid) return;

        NetService.APost("/reservations", modalData).then(resp => {
            if (resp.status === 200) {

                NetService.AGet(`/drivers/available?startDateTime=${modalData.startDateTime}&endDateTime=${modalData.endDateTime}&regionId=${modalData.regionId}&companyId=${modalData.companyId}`)
                    .then(o => setDrivers(o.data.data.drivers));

                setCreateModal(false);
                setSuccessSnackbar({ open: true, message: resp.data.message });
                setLatestReservationData(resp.data.data.Reservation);
                setAssignDriverModal(true);
            } else {
                setErrorSnackbar({ open: true, message: resp.data.message });
            }
        });

    }

    function handleDriverAssign(reservationId: number, driverId: number, scooterId: number) {

        let temp = reservationDriverAssignments;
        temp.reservationId = reservationId;
        const item = temp.assignments.find(o => o.scooterId === scooterId);
        if (item !== undefined) {
            temp.assignments.splice(temp.assignments.indexOf(item), 1);
        }
        temp.assignments.push({ driverId: driverId, scooterId: scooterId });

        setReservationDriverAssignments(temp);
    }

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setModalData({
                ...modalData,
                [parent]: {
                    ...modalData[parent as keyof typeof modalData] as {},
                    [child]: value
                }
            })
        ) : (
            setModalData({ ...modalData, [parent]: value })
        )
    }

    function handleDriverAssignSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APost("/reservations/assignee-driver", reservationDriverAssignments)
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
    }

    function handleEditReservation(e: FormEvent) {
        e.preventDefault();

        NetService.APut(`/reservation/${editData.id}`, editData)
            .then(resp => {
                if (resp.status === 200) {
                    setEditModal(false);
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
    }

    function handleScooterAmountSuggestion() {

        const scooterAmount = highestAvailableScooterAmount();

        setModalData({ ...modalData, numberOfScooters: scooterAmount });
        validateReservation(scooterAmount);
    }

    function ResToUpdate(res: Reservation) {

        const value = new ReservationUpdate();
        value.id = res.id;
        value.startDateTime = dayjs(res.startDateTime);
        value.endDateTime = dayjs(res.endDateTime);

        return value;
    }

    function colorPickersDay(date: Dayjs) {
        if ((date.isBefore(dayjs().startOf("day"))) ||
            (modalData.startDateTime !== undefined && date.isBefore(modalData.startDateTime)) ||
            (modalData.endDateTime !== undefined && date.isAfter(modalData.endDateTime))) return null;

        const availableScooters = availabilityData.find(o => dayjs(o.date).isSame(date))?.numberOfAvailableScooters ?? 0;

        return (availableScooters >= modalData.numberOfScooters) ? "#5f5a" : "#f55a";
    }

    function handleDateTimeRangePickerOpen() {

        NetService.APost("/scooters/available-days", {
            startDateTime: dayjs(),
            endDateTime: dayjs().add(1, "month").endOf("month"),
            regionId: modalData.regionId,
            hubId: modalData.hubId,
        }).then(resp => setAvailabilityData(resp.data.data.availabilityByDays));
    }

    function validateReservation(scooterAmount?: number) {

        const numberOfScooters = scooterAmount ?? modalData.numberOfScooters;

        const result = availabilityData.filter(o => modalData.startDateTime !== undefined && modalData.endDateTime !== undefined &&
            dayjs(o.date) >= modalData.startDateTime && dayjs(o.date) <= modalData.endDateTime)
            .some(o => o.numberOfAvailableScooters >= numberOfScooters);

        setIsReservationValid(result);
    }


    function handleScooterAmountChange(e: { target: { value: any } }) {

        const value = (Number(e.target.value) > 1) ? Number(e.target.value) : 1;
        setModalData({ ...modalData, numberOfScooters: value });
        validateReservation(value);
    }

    function handleMonthChange(month: Dayjs) {

        NetService.APost("/scooters/available-days", {
            startDateTime: (month.isBefore(dayjs(), "month") || month.isSame(dayjs(), "month")) ? dayjs() : month.startOf("month"),
            endDateTime: (month.isBefore(dayjs(), "month") || month.isSame(dayjs(), "month")) ? dayjs().add(1, "month").endOf("month") : month.add(1, "month").endOf("month"),
            regionId: modalData.regionId,
            hubId: modalData.hubId,
        }).then(resp => setAvailabilityData(resp.data.data.availabilityByDays));
    }

    function checkAvailabilityForHub(hubId: number) {

        if (modalData.startDateTime === undefined || modalData.endDateTime === undefined) return 0;

        return NetService.APost("/scooters/available-days", {
            startDateTime: modalData.startDateTime?.add(dayjs().hour(), "hours"),
            endDateTime: modalData.endDateTime,
            regionId: modalData.regionId,
            hubId: hubId,
        }).then(resp => {
            const data: AvailabilityData[] = resp.data.data.availabilityByDays;

            return data.every(o => o.numberOfAvailableScooters >= modalData.numberOfScooters);
        });
    }

    function highestAvailableScooterAmount() {

        let amount = Number.MAX_VALUE;
        availabilityData.forEach(o => {
            if (amount > o.numberOfAvailableScooters) amount = o.numberOfAvailableScooters;
        })

        return amount;
    }

    const overriteColumns = React.useMemo<MRT_ColumnDef<Reservation>[]>(
        () => [
            {
                accessorFn: (o) => dayjs(o.startDateTime).format("DD.MM.YYYY hh:mm"),
                accessorKey: "startDateTime",
                header: strings.reservation.startDateTime,
                filterVariant: "datetime",
                muiFilterDateTimePickerProps: { ampm: false },
            },
            {
                accessorFn: (o) => dayjs(o.endDateTime).format("DD.MM.YYYY hh:mm"),
                accessorKey: "endDateTime",
                header: strings.reservation.endDateTime,
                filterVariant: "datetime",
                muiFilterDateTimePickerProps: { ampm: false }
            },
            {
                accessorFn: (o) => dayjs(o.createdAt).format("DD.MM.YYYY hh:mm"),
                accessorKey: "createdAt",
                header: strings.reservation.createdAt,
                filterVariant: "datetime",
                muiFilterDateTimePickerProps: { ampm: false },
                enableColumnFilter: false
            },
            {
                accessorFn: (o) => (o.modifiedAt !== null) ? dayjs(o.modifiedAt).format("DD.MM.YYYY hh:mm") : "-",
                accessorKey: "modifiedAt",
                header: strings.reservation.modifiedAt,
                filterVariant: "datetime",
                muiFilterDateTimePickerProps: { ampm: false },
                enableColumnFilter: false
            },
            {
                accessorFn: (o) => (o.canceledAt !== null) ? dayjs(o.canceledAt).format("DD.MM.YYYY hh:mm") : "-",
                accessorKey: "canceledAt",
                header: strings.reservation.canceledAt,
                filterVariant: "datetime",
                muiFilterDateTimePickerProps: { ampm: false },
                enableColumnFilter: false
            },
            {
                accessorFn: (o) => (
                    <IconButton onClick={() => {
                        setDetailData(o.company);
                        setDetailModal(true);
                    }}>
                        <Info />
                    </IconButton>
                ),
                accessorKey: "company",
                header: strings.reservation.company,
                filterVariant: "select",
                enableColumnFilter: AuthService.HasAnyRole([Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN])
            },
            {
                accessorKey: "entityStatus",
                header: strings.reservation.entityStatus,
                filterVariant: "select",
                filterSelectOptions: Object.keys(EntityStatus).filter(o => isNaN(Number(o)))
            }
        ],
        []
    );

    return (

        <div>
            <CrudTable cancelRequest enableDelete enableFiltering onClick={(e) => { setEditData(ResToUpdate(e)); setEditModal(true); console.log(e); }} overriteColumns={overriteColumns} endpoint={AuthService.IsAdmin() ? `/reservations` : `/reservations?companyId=${AuthService.GetCompanyId()}`} dataType={AssignReservation} dataInstance={new AssignReservation()} renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.reservation.createReservation}</Button>
                    </div>
                )
            }} data={reservations.map(o => o.reservation)} columns={[]} />

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >
                            <Grid item xs={3}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.reservation.region}</InputLabel>
                                    <Select label={strings.reservation.region} name='regionId' onChange={(e) => {
                                        setModalData({ ...modalData, regionId: Number(e.target.value) });
                                        NetService.AGet(`/hubs?regionIds=${e.target.value}`)
                                            .then(o => setHubs(o.data.data.Hubs));
                                    }} >
                                        {regions?.map(o => {
                                            return <MenuItem value={o.id}>{o.regionName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl disabled={hubs === undefined} required fullWidth>
                                    <InputLabel>{strings.reservation.hub}</InputLabel>
                                    <Select label={strings.reservation.hub} name='hubId' onChange={handleChange} >
                                        {hubs?.map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {companies.length !== 0 &&
                                <Grid item xs={3}>
                                    <FormControl required fullWidth>
                                        <InputLabel>{strings.reservation.company}</InputLabel>
                                        <Select label={strings.reservation.company} name='companyId' onChange={handleChange} >
                                            {companies.map(o => {
                                                return <MenuItem value={o.id}>{o.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={3}>
                                <TextField type='number' required label={strings.reservation.numberOfScooters} name='numberOfScooters' fullWidth value={modalData.numberOfScooters} onChange={handleScooterAmountChange} />
                            </Grid>

                            <Grid item xs={12}>
                                <DateTimeRangePicker onMonthChanged={handleMonthChange} onClose={validateReservation} onOpen={handleDateTimeRangePickerOpen} value={{ start: modalData.startDateTime, end: modalData.endDateTime }} pickersDayColor={colorPickersDay} minDate={dayjs().startOf("day")} disabled={modalData.regionId === undefined || modalData.hubId === undefined} onDateTimeChanged={(start, end) => setModalData({ ...modalData, startDateTime: start, endDateTime: end })} />
                            </Grid>

                            {!isReservationValid &&
                                <div style={{ padding: "10px 0px 0px 15px" }}>
                                    <Grid item xs={12}>
                                        <Typography color="#f33">Im ausgewählten Zeitraum {modalData.numberOfScooters === 1 ? "ist" : "sind"} nicht immer {modalData.numberOfScooters} Scooter verfügbar! <br />
                                            Bitte wähle eine Option oder ändere das Datum!</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Stack direction="row" flexWrap="wrap">
                                            {highestAvailableScooterAmount() > 0 &&
                                                <Chip sx={{ margin: "2px" }} label={`Scooter Anzahl ${highestAvailableScooterAmount()}`} onClick={() => handleScooterAmountSuggestion()} />
                                            }
                                            {hubs?.filter(o => checkAvailabilityForHub(o.id)).map(o => {
                                                return <Chip sx={{ margin: "2px" }} label={`XPT ${o.name}`} onClick={() => { setModalData({ ...modalData, hubId: o.id }); }} />
                                            })}
                                        </Stack>
                                    </Grid>
                                </div>
                            }
                        </Grid>

                        <Button style={{ margin: "5px 5px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.reservation.createReservation}</Button>
                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='error' onClick={() => setCreateModal(false)} >{strings.cancel}</Button>
                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={assignDriverModal} onClose={() => setAssignDriverModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.reservation.assignDriverHeader}</a>

                    <form onSubmit={handleDriverAssignSubmit} >
                        <Grid style={{ display: "flex", alignItems: "center", padding: "20px 0px 0px 0px" }} container spacing={2} >

                            {latestReservationData.map(o => {
                                return <Grid style={{ display: "flex", alignItems: "center", padding: "10px 0px 0px 0px" }} container spacing={2} xs={12}>
                                    <Grid item xs={5} >
                                        <InputLabel style={{ fontSize: 20 }} >{scooters.find(f => f.id === o.scooterId)?.name}</InputLabel>
                                    </Grid>
                                    <Grid item xs={7} >
                                        <FormControl fullWidth>
                                            <InputLabel>{strings.driverName}</InputLabel>
                                            <Select label={strings.driverName} onChange={(e) => handleDriverAssign(o.reservationId, Number(e.target.value), o.scooterId)} >
                                                {drivers.map(o => {
                                                    return <MenuItem value={o.id}>{o.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            })}

                        </Grid>

                        <Button style={{ margin: "10px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.reservation.assignDriver}</Button>
                        <Button style={{ margin: "10px 0px 0px 5px" }} size='large' variant='contained' color='info' onClick={() => { setAssignDriverModal(false); window.location.reload(); }}  >{strings.later}</Button>


                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={editModal} onClose={() => setEditModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.reservation.editReservation}</a>

                    <form onSubmit={handleEditReservation} >
                        <Grid style={{ display: "flex", alignItems: "center", padding: "10px 0px" }} container spacing={2} >

                            <Grid item xs={12} >
                                <DateTimeRangePicker value={{ start: editData.startDateTime, end: editData.endDateTime }} onDateTimeChanged={(start, end) => setEditData({ ...editData, startDateTime: start, endDateTime: end })} />
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "10px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.save}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>

            <Snackbar open={successSnackbar.open} autoHideDuration={6000} onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} >
                <Alert onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} severity='success' sx={{ width: '100%' }}>
                    {successSnackbar.message}
                </Alert>
            </Snackbar>

            <Dialog open={detailModal} onClose={() => setDetailModal(false)} PaperProps={{ style: detailStyle }}  >
                <CompaniesDetail company={detailData} onClose={() => setDetailModal(false)} />
            </Dialog>
        </div>
    );
};
