import axios from "axios";
import AuthService from "./AuthService";

export default class NetService {

    public static Get(url: string) {

        return axios.get(process.env.REACT_APP_API_URL + url, {
            validateStatus: status => true
        });
    }

    public static AGet(url: string) {

        return axios.get(process.env.REACT_APP_API_URL + url, {
            headers: {
                "Authorization": "Bearer " + AuthService.GetToken()
            },
            validateStatus: status => true
        });
    }

    public static Post(url: string, body: any) {

        return axios.post(process.env.REACT_APP_API_URL + url, body, {
            validateStatus: status => true
        });
    }

    public static APost(url: string, body: any) {

        return axios.post(process.env.REACT_APP_API_URL + url, body, {
            headers: {
                "Authorization": "Bearer " + AuthService.GetToken()
            },
            validateStatus: status => true
        });
    }

    public static Put(url: string, body: any) {

        return axios.put(process.env.REACT_APP_API_URL + url, body, {
            validateStatus: status => true
        });
    }

    public static APut(url: string, body: any) {

        return axios.put(process.env.REACT_APP_API_URL + url, body, {
            headers: {
                "Authorization": "Bearer " + AuthService.GetToken()
            },
            validateStatus: status => true
        });
    }

    public static Delete(url: string) {

        return axios.delete(process.env.REACT_APP_API_URL + url, {
            validateStatus: status => true
        });
    }

    public static ADelete(url: string) {

        return axios.delete(process.env.REACT_APP_API_URL + url, {
            headers: {
                "Authorization": "Bearer " + AuthService.GetToken()
            },
            validateStatus: status => true
        });
    }
}