import { FormEvent, useEffect, useState } from 'react'
import NetService from '../../services/NetService'
import CrudTable from '../../components/CrudTable'
import { Alert, Button, Card, Grid, Modal, Snackbar, TextField } from '@mui/material'
import SnackbarData from '../../common/models/SnackbarData'
import Hub from '../../common/models/Hub'
import Region from '../../common/models/Region'
import Scooter from '../../common/models/Scooter'
import { getLocalizedStrings } from '../../App'


class ExtendedRegion extends Region {
    @Reflect.metadata("type", Number)
    hubCount!: number;
    @Reflect.metadata("type", Number)
    scooterCount!: number;
}


export default function RegionsPage() {

    const [regions, setRegions] = useState<Region[]>([]);
    const [createData, setCreateData] = useState<Region>(new Region());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [scooters, setScooters] = useState<Scooter[]>([]);
    const [editData, setEditData] = useState<Region>(new Region());
    const [editModal, setEditModal] = useState<boolean>(false);
    const [hubs, setHubs] = useState<Hub[]>([]);
    const strings = getLocalizedStrings();


    useEffect(() => {

        NetService.AGet("/scooters")
            .then(resp => setScooters(resp.data.data.Scooters));

        NetService.AGet("/hubs")
            .then(resp => setHubs(resp.data.data.Hubs));

        NetService.AGet("/regions")
            .then(resp => setRegions(resp.data.data.Regions));

    }, []);

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APost("/regions", createData);
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ message: "Failed to create Region!", open: true });
                }
            });
        }
    }

    function handleEditSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APut(`/regions/${editData.id}`, editData)
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            })
    }

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setCreateData({
                ...createData,
                [parent]: {
                    ...createData[parent as keyof typeof createData] as {},
                    [child]: value
                }
            })
        ) : (
            setCreateData({ ...createData, [parent]: value })
        )
    }

    function handleCreateClick() {
        setCreateModal(true);
    }

    function handleDelete(id: number) {

        const updatedRegions: Region[] = regions.slice();
        const regionToDelete = updatedRegions.find(o => o.id === id);
        console.log(updatedRegions);
        console.log(regionToDelete);
        if (regionToDelete !== undefined) {
            updatedRegions.splice(updatedRegions.indexOf(regionToDelete), 1);

            setRegions(updatedRegions);
        }
    }

    return (

        <div>
            <CrudTable cancelRequest endpoint='/regions' enableDelete dataType={ExtendedRegion} dataInstance={new ExtendedRegion()} enableFilters={false} renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={handleCreateClick}>{strings.create}</Button>
                    </div>
                )
            }} columns={[]} data={regions.map(o => {
                let reg: ExtendedRegion = new ExtendedRegion();
                reg.id = o.id;
                reg.regionName = o.regionName;
                reg.hubCount = hubs.filter(f => f.region.id === o.id).length;
                reg.scooterCount = scooters.filter(f => f.region.id === o.id).length;

                return reg;
            })} onClick={(e: ExtendedRegion) => { setEditModal(true); setEditData({ id: e.id, regionName: e.regionName }) }} onDeleted={handleDelete} />

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.region.create}</a>

                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >

                            <Grid item xs={12}>
                                <TextField type='text' required label={strings.region.regionName} name='regionName' fullWidth value={createData.regionName} onChange={handleChange} />
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={editModal} onClose={() => setEditModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >Editieren</a>

                    <form onSubmit={handleEditSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >

                            <Grid item xs={12}>
                                <TextField type='text' required label={strings.region.regionName} name='regionName' fullWidth value={editData.regionName} onChange={(e) => setEditData({ ...editData, regionName: e.target.value })} />
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>

        </div>
    );
};
