import React, { FormEvent, useEffect, useState } from 'react';
import NetService from '../../services/NetService';
import Scooter, { ScooterReservation } from '../../common/models/Scooter';
import CrudTable from '../../components/CrudTable';
import ScooterDetail from './ScooterDetail';
import { Alert, Button, Card, Dialog, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Snackbar, TextField } from '@mui/material';
import Region from '../../common/models/Region';
import Hub from '../../common/models/Hub';
import SnackbarData from '../../common/models/SnackbarData';
import Driver from '../../common/models/Driver';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'material-react-table';
import { EntityStatus } from '../../common/enums/EntityStatus';
import { getLocalizedStrings } from '../../App';
import AuthService from '../../services/AuthService';
import Reservation from '../../common/models/Reservation';

export default function ScootersPage() {

    const [scooters, setScooters] = useState<Scooter[]>([]);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [detailModal, setDetailModal] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<Scooter>(new Scooter());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [createData, setCreateData] = useState<Scooter>(new Scooter());
    const [regions, setRegions] = useState<Region[]>([]);
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [assignDriverModal, setAssignDriverModal] = useState<boolean>(false);
    const [assignDriverScooter, setAssignDriverScooter] = useState<Scooter>();
    const [assignDriverReservation, setAssignDriverReservation] = useState<ScooterReservation>();
    const [assignDriverDriver, setAssignDriverDriver] = useState<Driver>();
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const strings = getLocalizedStrings();

    const detailStyle: React.CSSProperties = {
        height: '100vh',
        width: '40vw',
        maxWidth: 'none',
        margin: 0,
        backgroundColor: '#000f',
        borderRadius: 0,
        position: 'fixed',
        top: 0,
        right: detailModal ? 0 : '-40vw',
        transition: 'right 0.3s ease-out',
        outline: 'none',
    };

    useEffect(() => {

        NetService.AGet("/scooters")
            .then(resp => {
                const data: Scooter[] = resp.data.data.Scooters;
                setScooters(data.filter(o => EntityStatus[o.entityStatus as keyof typeof EntityStatus] !== EntityStatus.DELETED));
            });

        NetService.AGet(AuthService.IsAdmin() ? "/drivers" : `/drivers?companyId=${AuthService.GetCompanyId()}`)
            .then(resp => setDrivers(resp.data.data.drivers));

        NetService.AGet("/regions")
            .then(resp => setRegions(resp.data.data.Regions));

        NetService.AGet("/hubs")
            .then(resp => setHubs(resp.data.data.Hubs));

        NetService.AGet(AuthService.IsAdmin() ? `/reservations` : `/reservations?companyId=${AuthService.GetCompanyId()}`)
            .then(resp => setReservations(resp.data.data.reservations));

    }, []);
    // test

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APost("/scooters", createData);
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 201) {
                    setCreateModal(false);
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
        }
    }

    function handleDriverAssignSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APost("/reservations/assignee-driver", { reservationId: assignDriverReservation?.id, scooterId: assignDriverScooter?.id, driverId: assignDriverDriver?.id })
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
    }

    function handleDetailClick(value: Scooter) {

        setDetailData(value);
        setDetailModal(true);
    }

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setCreateData({
                ...createData,
                [parent]: {
                    ...createData[parent as keyof typeof createData] as {},
                    [child]: value
                }
            })
        ) : (
            setCreateData({ ...createData, [parent]: value })
        )
    }

    const overriteColumns = React.useMemo<MRT_ColumnDef<Scooter>[]>(
        () => [
            {
                accessorKey: "entityStatus",
                header: strings.scooter.entityStatus
            },
            {
                accessorKey: "status",
                header: ""
            }
        ],
        []
    );


    return (
        <div>

            <CrudTable limitColumns={9} overriteColumns={overriteColumns} onClick={handleDetailClick} endpoint='/scooters' dataType={Scooter} dataInstance={new Scooter()} enableFilters={false} cancelRequest renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.create}</Button>
                        <Button style={{ margin: "0px 10px" }} variant='contained' color='info' onClick={() => setAssignDriverModal(true)} >{strings.scooter.assignDriver}</Button>
                    </div>
                )
            }} data={scooters} columns={[]} />

            <Dialog open={detailModal} onClose={() => setDetailModal(false)} PaperProps={{ style: detailStyle }}  >
                <ScooterDetail editEnabled={true} scooter={detailData} onClose={() => setDetailModal(false)} />
            </Dialog>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.scooter.create}</a>

                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <TextField type='text' required label={strings.scooter.name} name='name' fullWidth value={createData.name} onChange={handleChange} />
                            </Grid>


                            <Grid item xs={6}>
                                <TextField type='text' required label={strings.scooter.model} name='model' fullWidth value={createData.model} onChange={handleChange} />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField type='number' required label={strings.scooter.serialNumber} name='serialNumber' fullWidth value={createData.serialNumber} onChange={handleChange} />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.scooter.region}</InputLabel>
                                    <Select label={strings.scooter.region} value={createData.regionId} onChange={(e) => setCreateData({ ...createData, regionId: Number(e.target.value) })} >
                                        {regions?.map(o => {
                                            return <MenuItem value={o.id}>{o.regionName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.scooter.hub}</InputLabel>
                                    <Select label={strings.scooter.hub} value={createData.hubId} onChange={(e) => setCreateData({ ...createData, hubId: Number(e.target.value) })} >
                                        {hubs?.filter(f => f.region.id === createData.regionId).map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>


                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={assignDriverModal} onClose={() => setAssignDriverModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.scooter.assignDriver}</a>

                    <form onSubmit={handleDriverAssignSubmit} >
                        <Grid container spacing={2} >

                            <Grid item xs={6} >
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.reservationName}</InputLabel>
                                    <Select label={strings.reservationName} onChange={(e) => setAssignDriverReservation(assignDriverScooter?.scooterReservations.find(o => o.id === e.target.value))} >
                                        {reservations.filter(f => f.endDateTime >= dayjs()).map(o => {
                                            return <MenuItem value={o.id}>{o.company.name} | {dayjs(o.startDateTime).format("DD.MM.YYYY hh:mm")} - {dayjs(o.endDateTime).format("DD.MM.YYYY hh:mm")}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} >
                                <FormControl disabled={assignDriverReservation === undefined} required fullWidth>
                                    <InputLabel>{strings.scooterName}</InputLabel>
                                    <Select label={strings.scooterName} onChange={(e) => setAssignDriverScooter(scooters.find(o => o.id === e.target.value))} >
                                        {scooters.map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} >
                                <FormControl disabled={assignDriverScooter === undefined || assignDriverReservation === undefined} required fullWidth>
                                    <InputLabel>{strings.driverName}</InputLabel>
                                    <Select label={strings.driverName} onChange={(e) => setAssignDriverDriver(drivers.find(o => o.id === e.target.value))} >
                                        {drivers.filter(f => f.region === null || f.region.id === assignDriverScooter?.region.id).map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>

        </div>
    );
};
