import Location from "./Location";
import Region from "./Region";

@Reflect.metadata("name", "Hub")
export default class Hub {
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", String)
    name!: string;
    @Reflect.metadata("type", Object)
    location: Location = new Location();
    @Reflect.metadata("type", Object)
    region: Region = new Region();
}