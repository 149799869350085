
@Reflect.metadata("name", "Invoice")
export default class Invoice {
    @Reflect.metadata("type", Number)
    id: number = 0;
    @Reflect.metadata("type", String)
    customerName: string = "";
    @Reflect.metadata("type", String)
    customerAddress: string = "";
    @Reflect.metadata("type", String)
    postalInfo: string = "";
    @Reflect.metadata("type", Number)
    companyId!: number;
    @Reflect.metadata("type", String)
    customerNumber: string = "";
    @Reflect.metadata("type", String)
    uidNumber: string = "";
    @Reflect.metadata("type", String)
    rentalAgreementNumber: string = "";
    @Reflect.metadata("type", String)
    scooterName: string = "";
    @Reflect.metadata("type", String)
    scooterPosition: string = "";
    @Reflect.metadata("type", Number)
    scooterGrossPrice: number = 0;
    @Reflect.metadata("type", String)
    serviceName: string = "";
    @Reflect.metadata("type", String)
    servicePosition: string = "";
    @Reflect.metadata("type", Number)
    serviceGrossPrice: number = 0;
    @Reflect.metadata("type", String)
    additionalDescription: string = "";
    @Reflect.metadata("type", Number)
    scooterNetPrice: number = 0;
    @Reflect.metadata("type", Number)
    scooterUst: number = 0;
    @Reflect.metadata("type", Number)
    serviceNetPrice: number = 0;
    @Reflect.metadata("type", Number)
    serviceUst: number = 0;
    @Reflect.metadata("type", Number)
    net: number = 0;
    @Reflect.metadata("type", Number)
    ust: number = 0;
    @Reflect.metadata("type", Number)
    gross: number = 0;
}