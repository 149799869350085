import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from '../../assets/Jumug-Logo.png';
import NetService from '../../services/NetService';
import { Grid, Paper } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { getLocalizedStrings } from '../../App';

export default function EmailPasswordReset() {

    const [success, setSuccess] = React.useState<boolean>(false);
    const strings = getLocalizedStrings();


    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const email = data.get("email")?.toString() ?? ""

        NetService.Post("/auth/forgot-password", {
            "email": email
        })
            .then(o => {
                if (o.status === 200) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            });

    };

    return (
        <div>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Logo} />
                    <Typography component="h1" variant="h5">
                        {strings.resetPassword.header}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            label={strings.resetPassword.email}
                            type="email"
                            id="email"
                            autoComplete="current-email"
                        />

                        {!success ?
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {strings.resetPassword.sendRequest}
                            </Button>
                            :
                            <Paper style={{ padding: "10px", display: "flex", justifyContent: "center", transition: "all 0.3s ease-in" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                        <CheckCircle color='success' />
                                    </Grid>

                                    <Grid item xs={10}>
                                        {strings.resetPassword.requestSuccessful}
                                    </Grid>
                                </Grid>
                            </Paper>
                        }

                    </Box>
                </Box>
            </Container>
        </div>
    );
}