import Company from "./Company";
import dayjs, { Dayjs } from "dayjs";

@Reflect.metadata("name", "Reservation")
export default class Reservation {
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", Date)
    startDateTime!: Dayjs;
    @Reflect.metadata("type", Date)
    endDateTime!: Dayjs;
    @Reflect.metadata("type", String)
    entityStatus!: string;
    @Reflect.metadata("type", Object)
    company!: Company;
    @Reflect.metadata("type", Date)
    createdAt!: Date;
    @Reflect.metadata("type", Date)
    modifiedAt!: Date;
    @Reflect.metadata("type", Date)
    canceledAt!: Date;
}

@Reflect.metadata("name", "Assign")
export class Assign {
    @Reflect.metadata("type", Number)
    driverId!: number;
    @Reflect.metadata("type", String)
    scooterName!: string;
    @Reflect.metadata("type", String)
    driverEmail!: string;
    @Reflect.metadata("type", Number)
    scooterId!: number;
}

@Reflect.metadata("name", "AssignReservation")
export class AssignReservation {
    @Reflect.metadata("type", Object)
    reservation!: Reservation;
    @Reflect.metadata("type", Object)
    assigns!: Assign[];
}

export class ReservationCreation {
    startDateTime: Dayjs | undefined;
    endDateTime: Dayjs | undefined;
    regionId!: number;
    companyId!: number;
    hubId!: number;
    numberOfScooters: number = 1;
}

export class ReservationUpdate {
    startDateTime?: Dayjs;
    endDateTime?: Dayjs;
    id!: number;
}