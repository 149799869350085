import React, { FormEvent, useState } from 'react'
import NetService from '../../services/NetService'
import CrudTable from '../../components/CrudTable'
import { Alert, Button, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Snackbar, TextField } from '@mui/material'
import SnackbarData from '../../common/models/SnackbarData'
import Hub from '../../common/models/Hub'
import { MRT_ColumnDef } from 'material-react-table'
import Region from '../../common/models/Region'
import { getLocalizedStrings } from '../../App'

class CustomHubExport {
    constructor(hub: Hub) {
        this.name = hub.name;
        this.regionId = hub.region.id;
        this.latitude = Number(hub.location.latitude);
        this.longitude = Number(hub.location.longitude);
    }

    name!: string;
    regionId!: number;
    latitude!: number;
    longitude!: number;
}


export default function HubsPage() {

    const [createData, setCreateData] = useState<Hub>(new Hub());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [regions, setRegions] = useState<Region[]>([]);
    const [editData, setEditData] = useState<Hub>(new Hub());
    const [editModal, setEditModal] = useState<boolean>(false);
    const strings = getLocalizedStrings();


    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APost("/hubs", new CustomHubExport(createData));
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ message: "Failed to create Hub!", open: true });
                }
            });
        }
    }

    function handleEditSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APut(`/hubs/${editData.id}`, editData)
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            })
    }

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setCreateData({
                ...createData,
                [parent]: {
                    ...createData[parent as keyof typeof createData] as {},
                    [child]: value
                }
            })
        ) : (
            setCreateData({ ...createData, [parent]: value })
        )
    }

    function handleCreateClick() {
        setCreateModal(true);
        NetService.AGet("/regions")
            .then(o => setRegions(o.data.data.Regions));
    }

    const overriteColumns = React.useMemo<MRT_ColumnDef<Hub>[]>(
        () => [
            {
                accessorFn: (o) => o.region.regionName,
                accessorKey: "region",
                header: strings.hub.region
            },
            {
                accessorFn: (o) => o.location.country,
                accessorKey: "location",
                header: strings.hub.location
            },
        ],
        []
    );


    return (

        <div>
            <CrudTable onClick={(e) => { setEditModal(true); setEditData(e); }} overriteColumns={overriteColumns} endpoint='/hubs' enableDelete dataType={Hub} dataInstance={new Hub()} enableFilters={false} renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={handleCreateClick}>{strings.create}</Button>
                    </div>
                )
            }} columns={[]} data={[]} />

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.hub.create}</a>

                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >

                            <Grid item xs={6}>
                                <TextField type='text' required label={strings.hub.name} name='name' fullWidth value={createData.name} onChange={handleChange} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.hub.region}</InputLabel>
                                    <Select label={strings.hub.region} value={createData.region.id} name='region.id' onChange={handleChange} >
                                        {regions?.map(o => {
                                            return <MenuItem value={o.id}>{o.regionName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField type='number' required label={strings.location.latitude} name='location.latitude' fullWidth value={createData.location.latitude} onChange={handleChange} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField type='number' required label={strings.location.longitude} name='location.longitude' fullWidth value={createData.location.longitude} onChange={handleChange} />
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={editModal} onClose={() => setEditModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >Editieren</a>

                    <form onSubmit={handleEditSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >

                            <Grid item xs={12}>
                                <TextField type='text' required label={strings.region.regionName} name='name' fullWidth value={editData.name} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>

        </div>
    );
};
