import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login/LoginPage';
import AuthService from './services/AuthService';
import CompaniesPage from './pages/Companies/CompaniesPage';
import NavigationBar from './components/NavigationBar';
import { CssBaseline } from '@mui/material';
import ScootersPage from './pages/Scooters/ScootersPage';
import CompaniesRegister from './pages/Companies/CompaniesRegister';
import ReservationsPage from './pages/Reservations/ReservationsPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/de-at';
import ResetPassword from './pages/Login/ResetPassword';
import EmailPasswordReset from './pages/Login/EmailPasswordReset';
import { CustomThemeProvider } from './components/CustomThemeProdiver';
import BillingPage from './pages/Billing/BillingPage';
import GlobalConfig from './pages/Configuration/GlobalConfig';
import ScooterConfig from './pages/Configuration/ScooterConfig';
import AccessDeniedPage from './pages/Login/AccessDeniedPage';
import MapPage from './pages/Maps/MapPage';
import HubsPage from './pages/Hubs/HubsPage';
import RegionsPage from './pages/Regions/RegionsPage';
import DriverPage from './pages/Driver/DriverPage';
import { Roles } from './common/enums/Roles';
import LocalizedStrings from 'react-localization';
import Localization from './Localization.json'

dayjs.locale('de-at');
dayjs.prototype.toJSON = function () {
  return this.format('YYYY-MM-DD HH:mm:ss');
}
dayjs.prototype.toString = function () {
  return this.format('YYYY-MM-DD HH:mm:ss');
}

const noNavigationbar: string[] = ["/login", "/reset-password-init", "/reset-password", "/companies/register", "/accessDenied"];
const localizedStrings = new LocalizedStrings(Localization);

export function setLanguage(language: string) {

  localizedStrings.setLanguage(language);
}

export function getLocalizedStrings() {

  return localizedStrings;
}

export default function App() {

  return (
    <CustomThemeProvider>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <BrowserRouter>

          {!noNavigationbar.includes(window.location.pathname) &&
            <div>
              <NavigationBar />
            </div>
          }
          
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password-init" element={<EmailPasswordReset />} />

            <Route path="/companies/register" element={<CompaniesRegister />} />

            <Route path='/accessDenied' element={<AuthService.PrivateRoute />} >
              <Route path="/accessDenied" element={<AccessDeniedPage />} />
            </Route>

            <Route path='/' element={<AuthService.PrivateRoute />} >
              <Route path="/" element={<MapPage />} />
            </Route>

            <Route path='/companies' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/companies" element={<CompaniesPage />} />
            </Route>

            <Route path='/scooters' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/scooters" element={<ScootersPage />} />
            </Route>

            <Route path='/reservations' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN, Roles.ROLE_COMPANY]} />} >
              <Route path="/reservations" element={<ReservationsPage />} />
            </Route>

            <Route path='/billing' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/billing" element={<BillingPage />} />
            </Route>

            <Route path='/config' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/config" element={<GlobalConfig />} />
            </Route>

            <Route path='/config/scooter' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/config/scooter" element={<ScooterConfig />} />
            </Route>

            <Route path='/maps' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN, Roles.ROLE_COMPANY]} />} >
              <Route path="/maps" element={<MapPage />} />
            </Route>

            <Route path='/regions' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/regions" element={<RegionsPage />} />
            </Route>

            <Route path='/hubs' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN]} />} >
              <Route path="/hubs" element={<HubsPage />} />
            </Route>

            <Route path='/drivers' element={<AuthService.PrivateRoute roles={[Roles.ROLE_SUPERADMIN, Roles.ROLE_ADMIN, Roles.ROLE_COMPANY]} />} >
              <Route path="/drivers" element={<DriverPage />} />
            </Route>

          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </CustomThemeProvider>
  );
}