
export default class FilterConfig {

    constructor(startDateTimeEnabled: boolean = false, endDateTimeEnabled: boolean = false, regionIdEnabled: boolean = false, entityStatusEnabled: boolean = false, companyIdEnabled: boolean = false) {
        this.startDateTimeEnabled = startDateTimeEnabled;
        this.endDateTimeEnabled = endDateTimeEnabled;
        this.regionIdEnabled = regionIdEnabled;
        this.entityStatusEnabled = entityStatusEnabled;
        this.companyIdEnabled = companyIdEnabled;
    }

    startDateTimeEnabled: boolean;
    startDateTime: string = "datetime";

    endDateTimeEnabled: boolean;
    endDateTime: string = "datetime";

    regionIdEnabled: boolean;
    regionId: string = "select";

    entityStatusEnabled: boolean;
    entityStatus: string = "select";

    companyIdEnabled: boolean;
    companyId: string = "select";
}