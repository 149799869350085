import React, { useEffect, useState } from 'react'
import L, { LatLngExpression } from 'leaflet'
import { Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import jumugIcon from "../../assets/Jumug-Scooter-Icon.png"
import hubIcon from "../../assets/Hub-Icon.png"
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import NetService from '../../services/NetService';
import Scooter from '../../common/models/Scooter';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MaterialReactTable, MRT_ColumnDef, MRT_GlobalFilterTextField } from 'material-react-table';
import Hub from '../../common/models/Hub';
import Region from '../../common/models/Region';
import { getLocalizedStrings } from '../../App';
import { antPath } from 'leaflet-ant-path';
import MapScooterDetail from './MapScooterDetail';
import MovementHistory from '../../common/models/MovementHistory';
import dayjs, { Dayjs } from 'dayjs';
import ScooterControls from '../../common/models/ScooterControls';
import AuthService from '../../services/AuthService';


let ScooterIcon = L.icon({
    iconUrl: jumugIcon,
    shadowUrl: iconShadow,
    iconSize: [30, 35],
    iconAnchor: [15, 35]
});

let HubIcon = L.icon({
    iconUrl: hubIcon,
    shadowUrl: iconShadow,
    iconSize: [40, 40],
    iconAnchor: [20, 40]
});


export default function MapOverlay() {

    const [scooterData, setScooterData] = useState<Scooter[]>([]);
    const [hubData, setHubData] = useState<Hub[]>([]);
    const [regionData, setRegionData] = useState<Region[]>([]);
    const [regionFilter, setRegionFilter] = useState<number[]>([]);
    const [hubFilterActive, setHubFilterActive] = useState<boolean>(true);
    const [hiddenScooters, setHiddenScooters] = useState<number[]>([]);
    const [hiddenHubs, setHiddenHubs] = useState<number[]>([]);
    const [movementHistory, setMovementHistory] = useState<LatLngExpression[]>([]);
    const [detailPageActive, setDetailPageActive] = useState<boolean>(false);
    const [detailScooter, setDetailScooter] = useState<Scooter>(new Scooter());
    const [detailScooterControl, setDetailScooterControl] = useState<ScooterControls | undefined>();
    const strings = getLocalizedStrings();

    const map = useMap();


    useEffect(() => {

        NetService.AGet("/regions")
            .then(o => {
                setRegionData(o.data.data.Regions);

                const filter = window.localStorage.getItem("regionFilter");
                if (filter !== null) {
                    const desFilter = filter.split(',').map(o => Number(o));

                    setRegionFilter(desFilter);
                    updateDisplayRect(desFilter);
                }
            });

    }, []);

    function handleRegionChange(e: { target: { value: any; }; }) {

        const { value } = e.target;
        const updatedRegionFilter = regionFilter;

        if (!updatedRegionFilter.includes(Number(value))) {
            updatedRegionFilter.push(Number(value)); // who tf created this bs, fuck you typescript! Why do numbers get converted to strings as soon as they are put in a variable with type any?!?!?!? And why tf can't i change any to number?!??
        } else {
            updatedRegionFilter.splice(updatedRegionFilter.indexOf(Number(value)), 1);
        }

        setRegionFilter(updatedRegionFilter);
        window.localStorage.setItem("regionFilter", regionFilter.join());

        updateDisplayRect(updatedRegionFilter);
    }

    function updateDisplayRect(currentRegionFilter: number[]) {

        NetService.AGet(`/scooters?${(!AuthService.IsAdmin()) ? `companyId=${AuthService.GetCompanyId()}&` : ""}regionIds=${currentRegionFilter.join()}`)
            .then(o => setScooterData(o.data.data.Scooters));

        NetService.AGet(`/hubs?regionIds=${currentRegionFilter.join()}`)
            .then(o => {
                const hubs: Hub[] = o.data.data.Hubs;
                setHubData(hubs);

                if (hubs.length === 0) {
                    return;
                }

                const north: number = hubs.reduce((acum, current) =>
                    (acum > current.location.latitude) ? current.location.latitude : acum, 360);

                const east: number = hubs.reduce((acum, current) =>
                    (acum > current.location.longitude) ? current.location.longitude : acum, 360);

                const south: number = hubs.reduce((acum, current) =>
                    (acum < current.location.latitude) ? current.location.latitude : acum, -360);

                const west: number = hubs.reduce((acum, current) =>
                    (acum < current.location.longitude) ? current.location.longitude : acum, -360);


                const bounds = L.latLngBounds([north, east], [south, west]);
                map.fitBounds(bounds);
            });
    }

    function handleHubSelect(hub: Hub) {

        const scooters: Scooter[] = scooterData.filter(o => o.hub.id === hub.id);
        if (scooters.length === 0) {
            //setMapCenter([hub.location.latitude, hub.location.longitude]);
            //setMapZoom(16);
            //setMapRerender(dayjs().millisecond());
            return;
        }

        const north: number = scooters.reduce((acum, current) =>
            (acum > current.currentLocation.latitude) ? current.currentLocation.latitude : acum, 360);

        const east: number = scooters.reduce((acum, current) =>
            (acum > current.currentLocation.longitude) ? current.currentLocation.longitude : acum, 360);

        const south: number = scooters.reduce((acum, current) =>
            (acum < current.currentLocation.latitude) ? current.currentLocation.latitude : acum, -360);

        const west: number = scooters.reduce((acum, current) =>
            (acum < current.currentLocation.longitude) ? current.currentLocation.longitude : acum, -360);


        const bounds = L.latLngBounds([north, east], [south, west]);
        map.fitBounds(bounds);
    }

    function handleScooterSelect(scooter: Scooter) {

        NetService.AGet(`/movements/${scooter.id}?startDateTime=${dayjs().subtract(1, "day")}&endDateTime=${dayjs()}`)
            .then(resp => {
                const history: MovementHistory[] = resp.data.data.Movements;
                if (history.length !== 0) {
                    const coordinates = history.map(o => ({ lat: o.latitude, lng: o.longitude }));
                    setMovementHistory(coordinates);

                    const north: number = coordinates.reduce((acum, current) =>
                        (acum > current.lat) ? current.lat : acum, 360);

                    const east: number = coordinates.reduce((acum, current) =>
                        (acum > current.lng) ? current.lng : acum, 360);

                    const south: number = coordinates.reduce((acum, current) =>
                        (acum < current.lat) ? current.lat : acum, -360);

                    const west: number = coordinates.reduce((acum, current) =>
                        (acum < current.lng) ? current.lng : acum, -360);


                    const bounds = L.latLngBounds([north, east], [south, west]);
                    map.fitBounds(bounds);
                } else {
                    setMovementHistory([]);
                    const bounds = L.latLngBounds([scooter.currentLocation.latitude + 0.01, scooter.currentLocation.longitude + 0.01], [scooter.currentLocation.latitude - 0.01, scooter.currentLocation.longitude - 0.01]);
                    map.fitBounds(bounds);
                }
            });

        handleOpenScooterDetail(scooter);
    }

    function handleHiddenHubChange(original: Hub, newVisibility: boolean) {

        const hub: Hub = original;
        if (newVisibility) {
            const hubs: number[] = hiddenHubs.slice();
            hubs.splice(hubs.indexOf(hub.id), 1);

            const scooters: number[] = scooterData.filter(f => hubs.includes(f.hub.id)).map(o => o.id);

            setHiddenHubs(hubs);
            setHiddenScooters(scooters);
        } else {
            const newScooters: number[] = scooterData.filter(f => f.hub.id === hub.id).map(o => o.id);

            setHiddenHubs([...hiddenHubs, hub.id]);
            setHiddenScooters(hiddenScooters.concat(newScooters));
        }
    }

    function handleHiddenScooterChange(original: Scooter, newVisibility: boolean) {

        const scooter: Scooter = original;
        if (newVisibility) {
            const scooters: number[] = hiddenScooters.slice();
            scooters.splice(scooters.indexOf(scooter.id), 1);

            setHiddenScooters(scooters);
        } else {
            setHiddenScooters([...hiddenScooters, scooter.id]);
        }
    }

    function handleOpenScooterDetail(scooter: Scooter) {

        NetService.AGet(`/scooter-controls/${scooter.id}`)
            .then(resp => {
                if (resp.status === 200) {
                    setDetailScooterControl(resp.data.data.ScooterControls)
                }
                setDetailScooter(scooter);
                setDetailPageActive(true);
            });

    }

    function handleCloseScooterDetail() {

        setDetailPageActive(false);
        setMovementHistory([]);
    }

    function handleUpdateMovementHistory(start: Dayjs, end: Dayjs, scooter: Scooter) {

        NetService.AGet(`/movements/${scooter.id}?startDateTime=${start}&endDateTime=${end}`)
            .then(resp => {
                const history: MovementHistory[] = resp.data.data.Movements;
                if (history.length !== 0) {
                    const coordinates = history.map(o => ({ lat: o.latitude, lng: o.longitude }));
                    setMovementHistory(coordinates);

                    const north: number = coordinates.reduce((acum, current) =>
                        (acum > current.lat) ? current.lat : acum, 360);

                    const east: number = coordinates.reduce((acum, current) =>
                        (acum > current.lng) ? current.lng : acum, 360);

                    const south: number = coordinates.reduce((acum, current) =>
                        (acum < current.lat) ? current.lat : acum, -360);

                    const west: number = coordinates.reduce((acum, current) =>
                        (acum < current.lng) ? current.lng : acum, -360);


                    const bounds = L.latLngBounds([north, east], [south, west]);
                    map.fitBounds(bounds);
                } else {
                    setMovementHistory([]);
                    const bounds = L.latLngBounds([scooter.currentLocation.latitude + 0.01, scooter.currentLocation.longitude + 0.01], [scooter.currentLocation.latitude - 0.01, scooter.currentLocation.longitude - 0.01]);
                    map.fitBounds(bounds);
                }
            });
    }

    const hubColumns = React.useMemo<MRT_ColumnDef<Hub>[]>(
        () => [
            {
                accessorKey: "name",
                header: strings.hub.name
            },
            {
                accessorKey: "region.regionName",
                header: strings.hub.region
            }
        ],
        []
    );

    const scooterColumns = React.useMemo<MRT_ColumnDef<Scooter>[]>(
        () => [
            {
                accessorKey: "name",
                header: strings.scooter.name
            },
            {
                accessorKey: "hub.name",
                header: "XPT " + strings.hub.name
            }
        ],
        []
    );

    const AntPath = (p: { positions: any; options: any; }) => {
        const map = useMap();

        useEffect(() => {
            let antPolyline = antPath(p.positions, p.options);
            map.addLayer(antPolyline)
            return () => {
                map.removeLayer(antPolyline)
            }
        })
        return null
    }

    return (
        <div>
            <AntPath positions={movementHistory} options={{ delay: 800, dashArray: [10, 20] }} />

            {scooterData.filter(f => !hiddenScooters.includes(f.id)).map(o => {
                return <Marker icon={ScooterIcon} position={[o.currentLocation.latitude, o.currentLocation.longitude]} eventHandlers={{ click: () => handleScooterSelect(o) }} />
            })}

            {hubData.filter(f => !hiddenHubs.includes(f.id)).map(o => {
                return <Marker icon={HubIcon} position={[o.location.latitude, o.location.longitude]}>
                    <Popup>
                        {o.name}
                    </Popup>
                </Marker>
            })}

            <Grid style={{ position: "absolute", top: "30px", right: "30px", maxWidth: "480px", zIndex: 1000, opacity: "90%", visibility: !detailPageActive ? "visible" : "hidden" }} container spacing={2}>
                <Grid item xs={12}>
                    <Paper style={{ padding: "10px" }}>
                        <FormGroup row >
                            {regionData.map(o => {
                                return <FormControlLabel label={o.regionName} control={<Checkbox onChange={handleRegionChange} value={o.id} checked={regionFilter.includes(o.id)} />} />
                            })}
                        </FormGroup>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{ padding: "10px" }}>
                        <MaterialReactTable
                            data={hubFilterActive ? hubData as [] : scooterData as []}
                            columns={hubFilterActive ? hubColumns as [] : scooterColumns as []}
                            enablePagination
                            enableRowActions
                            enableSorting={false}
                            enableHiding={false}
                            initialState={{ pagination: { pageSize: 5, pageIndex: 0 }, showGlobalFilter: true }}
                            muiPaginationProps={{ rowsPerPageOptions: [5] }}
                            muiTableBodyCellProps={({ cell }) => ({
                                onClick: () => {
                                    if (cell.id.includes("mrt-row-actions")) return;

                                    if (hubFilterActive) {
                                        const hub: Hub = cell.row.original;
                                        handleHubSelect(hub);
                                    } else {
                                        const scooter: Scooter = cell.row.original;
                                        handleScooterSelect(scooter);
                                    }
                                },
                                sx: {
                                    cursor: "pointer",
                                },
                            })}
                            renderTopToolbar={({ table }) => {
                                return <Grid container >
                                    <Grid item xs={6}>
                                        <Stack direction="row" alignItems="center">
                                            <Typography>XPT</Typography>
                                            <Switch value={hubFilterActive} checked={!hubFilterActive} onChange={() => setHubFilterActive(!hubFilterActive)} />
                                            <Typography>Scooter</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MRT_GlobalFilterTextField table={table} />
                                    </Grid>
                                </Grid>
                            }}
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: "flex", gap: "1rem" }}>
                                    {hubFilterActive ?
                                        hiddenHubs.includes((row.original as Hub).id) ?
                                            <IconButton onClick={() => handleHiddenHubChange(row.original, true)} >
                                                <VisibilityOff sx={{ color: "GrayText" }} />
                                            </IconButton> :
                                            <IconButton onClick={() => handleHiddenHubChange(row.original, false)} >
                                                <Visibility />
                                            </IconButton>
                                        :
                                        hiddenScooters.includes((row.original as Scooter).id) ?
                                            <IconButton onClick={() => handleHiddenScooterChange(row.original, true)} >
                                                <VisibilityOff sx={{ color: "GrayText" }} />
                                            </IconButton> :
                                            <IconButton onClick={() => handleHiddenScooterChange(row.original, false)} >
                                                <Visibility />
                                            </IconButton>
                                    }
                                </Box>
                            )}
                        />
                    </Paper>
                </Grid>
            </Grid >

            <MapScooterDetail onRouteUpdate={handleUpdateMovementHistory} scooter={detailScooter} scooterControl={detailScooterControl} onClose={handleCloseScooterDetail} style={{ position: "absolute", top: "30px", right: "30px", maxWidth: "480px", zIndex: 1000, opacity: "90%", visibility: detailPageActive ? "visible" : "hidden" }} />
        </div>
    );
};
