import { MaterialReactTable, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_Row, MRT_TableOptions } from "material-react-table";
import React, { useEffect, useState } from "react";
import 'reflect-metadata'
import { Alert, Box, IconButton, Snackbar, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import NetService from "../services/NetService";
import { EntityStatus } from "../common/enums/EntityStatus";
import { Dayjs } from "dayjs";
import SnackbarData from "../common/models/SnackbarData";
import FilterConfig from "../common/models/FilterConfig";
import { getLocalizedStrings } from "../App";
import Reservation from "../common/models/Reservation";

type CrudTableProps<T extends Record<string, any>> = MRT_TableOptions<T> & {
    dataType: object;
    dataInstance: object;
    endpoint: string;
    limitColumns?: number;
    overriteColumns?: MRT_ColumnDef<T>[];
    hideDeleted?: boolean;
    onClick?: (value: T) => void;
    onDeleted?: (id: number) => void;
    enableDelete?: boolean;
    cancelRequest?: boolean;
    enableFiltering?: boolean;
    filterConfig?: FilterConfig;
    dataFilter?: (value: T) => boolean;
};

export default function CrudTable<T extends Record<string, any>>({ dataType, dataInstance, endpoint, limitColumns = 8, overriteColumns, enableDelete, hideDeleted, cancelRequest = false, onClick, onDeleted, enableFiltering = false, filterConfig = new FilterConfig(), dataFilter, ...props }: CrudTableProps<T>) {

    const [tableData, setTableData] = useState<T[]>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const strings = getLocalizedStrings();

    function formatEndpoint(value: string) {
        if (value.includes('-')) {
            return extractEndpointName(endpoint).split('-').map(o => o.charAt(0).toUpperCase() + o.slice(1)).join("");
        }
        else {
            return extractEndpointName(endpoint).charAt(0).toUpperCase() + extractEndpointName(endpoint).substring(1);
        }
    }

    function extractEndpointName(value: string) {

        return value.substring(1, (value.indexOf('?') !== -1) ? endpoint.indexOf('?') : undefined);
    }

    function filterToString(filter: MRT_ColumnFiltersState) {

        return filter.map(o => `${o.id}=${o.value}`).join("&");
    }

    useEffect(() => {

        if (cancelRequest) return;

        NetService.AGet(`${endpoint}${(columnFilters.length !== 0) ? (endpoint.includes('?')) ? "&" : "?" : ""}${filterToString(columnFilters)}`)
            .then(resp => {

                let values: T[] = resp?.data.data[formatEndpoint(endpoint)];
                if (values === undefined) {
                    values = resp?.data.data[extractEndpointName(endpoint)];
                }

                if (hideDeleted)
                    values = values.filter(o => Number(EntityStatus[o.entityStatus]) !== EntityStatus.DELETED);

                setTableData((dataFilter !== undefined) ? values.filter(o => dataFilter(o)) : values);
            });

    }, [columnFilters]);

    const tableColumns = React.useMemo<MRT_ColumnDef<T>[]>(
        () => {
            return Object.keys(dataInstance).filter(key => {
                if (key === "id") return false;
                const type = Reflect.getMetadata("type", dataInstance, key);
                const found = overriteColumns?.find(o => o.accessorKey === key);
                if (type === undefined) return false;
                if (type === Object && found === undefined) return false;

                return (type === Number || type === String || type === Dayjs || type === Date || type === Object) && found?.header !== "";
            }).slice(0, limitColumns).map(key => {
                const found = overriteColumns?.find(o => o.accessorKey === key);
                if (found !== undefined) return found as MRT_ColumnDef<T>;

                const className = Reflect.getMetadata("name", dataType);

                return {
                    accessorKey: key,
                    header: strings.getString(className.charAt(0).toLowerCase() + className.substring(1) + "." + key),
                    filterVariant: filterConfig[key as keyof FilterConfig] as any,
                    enableColumnFilter: filterConfig[`${key}Enabled` as keyof FilterConfig] as boolean ?? false
                };
            }).concat((overriteColumns !== undefined) ? overriteColumns.filter(col => Object.keys(dataInstance).find(o => o === col.accessorKey) === undefined).map(col => {

                return col as MRT_ColumnDef<T>;

            }) : []);
        },
        [],
    );

    function handleDeleteRow(row: MRT_Row<T>) {

        if (window.confirm(strings.deleteConfirm)) {
            NetService.ADelete(endpoint + "/" + (row.original as T).id)
                .then(resp => {
                    if (resp.status === 200) {
                        tableData.splice(row.index, 1);
                        setTableData([...tableData]);
                        if (cancelRequest && onDeleted === undefined) throw Error("CancelRequest specified but no onDeleted handle provided!");
                        else if (onDeleted !== undefined) onDeleted((row.original as T).id);
                    }
                    else {
                        setErrorSnackbar({ open: true, message: resp.data.message });
                    }
                });
        }
    }

    return (
        <div>
            {enableDelete
                ? <MaterialReactTable
                    {...props}
                    data={(props.data.length !== 0) ? props.data : tableData}
                    columns={tableColumns}
                    enableEditing
                    enableFilters={enableFiltering}
                    manualFiltering
                    manualSorting
                    initialState={{ showColumnFilters: true }}
                    muiTableBodyCellProps={({ cell }) => ({
                        onClick: () => {
                            if (onClick === undefined ||
                                cell.id.includes("mrt-row-actions") ||
                                Reflect.getMetadata("type", dataInstance, cell.column.id) === Object) return;

                            onClick(cell.row.original as T);
                        },
                        sx: {
                            cursor: "pointer",
                        },
                    })}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    onColumnFiltersChange={setColumnFilters}
                />
                :
                <MaterialReactTable
                    {...props}
                    data={(props.data.length !== 0) ? props.data : tableData}
                    columns={tableColumns}
                    enableFilters={enableFiltering}
                    manualFiltering
                    manualSorting
                    initialState={{ showColumnFilters: true }}
                    muiTableBodyCellProps={({ cell }) => ({
                        onClick: () => {
                            if (onClick === undefined ||
                                cell.id.includes("mrt-row-actions") ||
                                Reflect.getMetadata("type", dataInstance, cell.column.id) === Object) return;

                            onClick(cell.row.original as T);
                        },
                        sx: {
                            cursor: "pointer",
                        },
                    })}
                    onColumnFiltersChange={setColumnFilters}
                />
            }

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
}