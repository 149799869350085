import { FormEvent, useState } from 'react'
import NetService from '../../services/NetService'
import CrudTable from '../../components/CrudTable'
import { Alert, Button, Card, Grid, Modal, Snackbar, TextField } from '@mui/material'
import SnackbarData from '../../common/models/SnackbarData'
import ConfigProperties from '../../common/models/ConfigProperties'
import { getLocalizedStrings } from '../../App'

export default function GlobalConfig() {

    const [editModal, setEditModal] = useState<boolean>(false);
    const [editModalData, setEditModalData] = useState<ConfigProperties>(new ConfigProperties());
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const strings = getLocalizedStrings();

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        (child !== undefined) ? (
            setEditModalData({
                ...editModalData,
                [parent]: {
                    ...editModalData[parent as keyof typeof editModalData] as {},
                    [child]: value
                }
            })
        ) : (
            setEditModalData({ ...editModalData, [parent]: value })
        )
    }

    function handleEditClick(value: ConfigProperties) {

        setEditModalData(value);
        setEditModal(true);
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APut(`/config-properties/${editModalData.id}`, editModalData);
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 200) {
                    setEditModal(false);
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
        }
    }

    return (

        <div>
            <CrudTable onClick={handleEditClick} enableDelete endpoint='/config-properties' dataType={ConfigProperties} dataInstance={new ConfigProperties()} enableFilters={false} columns={[]} data={[]} />

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={editModal} onClose={() => setEditModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.configProperties.edit}</a>

                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >
                            <Grid item xs={6}>
                                <TextField label={strings.configProperties.frequencyOfStatusInfoUpdates} required fullWidth value={editModalData.frequencyOfStatusInfoUpdates} name='frequencyOfStatusInfoUpdates' onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label={strings.configProperties.configInterval} required fullWidth value={editModalData.configInterval} name='configInterval' onChange={handleChange} />
                            </Grid>
                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};