import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthService from '../../services/AuthService';
import Logo from '../../assets/Jumug-Logo.png';
import { getLocalizedStrings } from '../../App';

export default function LoginPage() {

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const strings = getLocalizedStrings();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email")?.toString() ?? ""
    const password = data.get("password")?.toString() ?? "";

    AuthService.Authorize(email, password)
      .then(result => {
        if (result.successful) {
          window.location.href = window.location.origin;
        } else {
          setErrorMessage(result.message);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={Logo} />
        <Typography component="h1" variant="h5">
          {strings.login.signIn}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={strings.login.email}
            name="email"
            autoComplete="email"
            autoFocus
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={strings.login.password}
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Grid container >
            <Grid item >
              <Typography variant="body2" color="error.main"  >
                {errorMessage}
              </Typography>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {strings.login.signIn}
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href="/reset-password-init" variant="body2">
                {strings.login.forgotPassword}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/companies/register" variant="body2">
                {strings.login.signUp}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Typography sx={{ mt: 8, mb: 4 }} variant="body2" color="text.secondary" align="center" >
        {'Copyright © '}
        <Link color="inherit" href="https://jumug.at/">
          {strings.logoName}
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Container>
  );
}