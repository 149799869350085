import React, { FormEvent, useState } from 'react';
import { Button, Card, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import CompanyRegister from '../../common/models/CompanyRegister';
import NetService from '../../services/NetService';
import { getLocalizedStrings } from '../../App';


export default function CompaniesRegister() {

    const [data, setData] = useState<CompanyRegister>(new CompanyRegister());

    const strings = getLocalizedStrings();

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const request = NetService.APost("/auth/companies/register", data);
        if (request !== undefined) {
            request.then(resp => {
                if (resp.status === 201) window.location.reload();
                else window.alert(resp.data.message);
            });
        }
    }

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        if (typeof value === 'string' && value.trim() === '') return;

        (child !== undefined) ? (
            setData({
                ...data,
                [parent]: {
                    ...data[parent as keyof typeof data] as {},
                    [child]: value
                }
            })
        ) : (
            setData({ ...data, [parent]: value })
        )
    }

    function handleCheckox(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        setData({ ...data, [parent]: !data.oneManCompany });
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                <a style={{ fontSize: 30 }} >{strings.company.registration}</a>

                <form onSubmit={handleSubmit} >
                    <Grid container spacing={2} style={{ padding: "15px 0px" }} >
                        <Grid item xs={6}>
                            <TextField type='text' required label={strings.company.name} name='name' fullWidth value={data.name} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField required type='email' label={strings.company.email} fullWidth name='email' value={data.email} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={5}>
                            <TextField type='password' required label={strings.company.password} fullWidth name='password' value={data.password} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField type='text' required label={strings.company.address} fullWidth name='address' value={data.address} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField type='email' required label={strings.company.contactPersonEmail} fullWidth name='contactPersonEmail' value={data.contactPersonEmail} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type='tel' required label={strings.company.contactPersonPhoneNumber} fullWidth name='contactPersonPhoneNumber' value={data.contactPersonPhoneNumber} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField type='text' inputProps={{ pattern: '^\d{6-11}$' }} required label={strings.company.gisaNumber} fullWidth name='gisaNumber' value={data.gisaNumber} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type='text' inputProps={{ pattern: '^ATU\d{6-10}$' }} required label={strings.company.uid} fullWidth name='uid' value={data.uid} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel style={{ display: "flex", justifyContent: "center" }} control={<Checkbox name='oneManCompany' value={data.oneManCompany} onChange={handleCheckox} />} label={strings.company.oneManCompany} />
                        </Grid>
                    </Grid>

                    <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                </form>

            </Card>
        </div>
    );
};