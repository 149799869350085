import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

type Theme = 'light' | 'dark';

interface ThemeContextProps {
    theme: Theme;
    toggleTheme: (isDark: boolean) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = (): ThemeContextProps => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export function CustomThemeProvider({ children }: { children: React.ReactNode }) {
    const [themeType, setThemeType] = useState<Theme>(window.localStorage.getItem("theme") as Theme ?? 'light');

    const toggleTheme = (isDark: boolean): void => {
        setThemeType(isDark ? 'dark' : 'light');
        window.localStorage.setItem("theme", isDark ? 'dark' : 'light');
    };

    const theme = createTheme({
        palette: {
            mode: themeType,
        },
    });

    const value: ThemeContextProps = {
        theme: themeType,
        toggleTheme,
    };

    return (
        <ThemeContext.Provider value={value}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
