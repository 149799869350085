import React, { FormEvent, useEffect, useState } from 'react'
import NetService from '../../services/NetService'
import Company from '../../common/models/Company'
import CrudTable from '../../components/CrudTable'
import { Alert, Button, Card, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Snackbar, TextField } from '@mui/material'
import Invoice from '../../common/models/Invoice'
import SnackbarData from '../../common/models/SnackbarData'
import { MRT_ColumnDef } from 'material-react-table'
import { Info } from '@mui/icons-material'
import CompaniesDetail from '../Companies/CompaniesDetail'
import { getLocalizedStrings } from '../../App'

export default function BillingPage() {

  const [modalData, setModalData] = useState<Invoice>(new Invoice());
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [successSnackbar, setSuccessSnackbar] = useState<SnackbarData>(new SnackbarData());
  const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<Company>(new Company());
  const [companies, setCompanies] = useState<Company[]>();
  const strings = getLocalizedStrings();


  const detailStyle: React.CSSProperties = {
    height: '100vh',
    width: '40vw',
    maxWidth: 'none',
    margin: 0,
    backgroundColor: '#000f',
    borderRadius: 0,
    position: 'fixed',
    top: 0,
    right: detailModal ? 0 : '-40vw',
    transition: 'right 0.3s ease-out',
    outline: 'none',
  };



  useEffect(() => {

    NetService.AGet("/companies")
      .then(resp => {
        setCompanies(resp.data.data.Companies);
      })

  }, []);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const request = NetService.APost("/invoices/generate-custom-pdf", modalData);
    if (request !== undefined) {
      request.then(resp => {
        if (resp.status === 200) {
          setSuccessSnackbar({ message: "Created Invoice!", open: true });

          window.location.reload();
        } else {
          setErrorSnackbar({ message: "Failed to create Invoice!", open: true });
        }
      });
    }
  }

  function handleChange(e: { target: { name: any; value: any; }; }) {

    const { name, value } = e.target;
    const [parent, child] = name.split(".");

    if (typeof value === 'string' && value.trim() === '') return;

    (child !== undefined) ? (
      setModalData({
        ...modalData,
        [parent]: {
          ...modalData[parent as keyof typeof modalData] as {},
          [child]: value
        }
      })
    ) : (
      setModalData({ ...modalData, [parent]: value })
    )
  }

  function handleCompanySelectChange(e: { target: { name: any; value: any; }; }) {

    const { value } = e.target;
    const company = companies?.find(o => o.id === value);

    if (company === undefined) {
      setModalData({ ...modalData, companyId: value });
    } else {
      setModalData({
        ...modalData, companyId: company.id,
        customerName: company.name,
        customerAddress: company.address,
        uidNumber: company.uid
      });
    }

  }

  function handleDetailOpen(id: number) {

    NetService.AGet(`/companies/${id}`)
      .then(resp => {
        setDetailData(resp.data.data.Company);
        setDetailModal(true);
      });

  }


  const overriteColumns = React.useMemo<MRT_ColumnDef<Invoice>[]>(
    () => [
      {
        accessorFn: (o) => (
          <IconButton disabled={o.companyId === null} onClick={() => {
            handleDetailOpen(o.companyId);
          }}>
            <Info />
          </IconButton>
        ),
        accessorKey: "companyId",
        header: strings.invoice.companyId
      }
    ],
    []
  );

  return (

    <div>
      <CrudTable overriteColumns={overriteColumns} endpoint='/invoices' enableDelete dataType={Invoice} dataInstance={new Invoice()} enableFilters={false} renderTopToolbarCustomActions={({ }) => {
        return (
          <div>
            <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.create}</Button>
          </div>
        )
      }} columns={[]} data={[]} />

      <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
        <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

          <a style={{ fontSize: 30 }} >{strings.company.registration}</a>

          <form onSubmit={handleSubmit} >
            <Grid container spacing={2} style={{ padding: "15px 0px" }} >
              <Grid item xs={4}>
                <FormControl required fullWidth>
                  <InputLabel>{strings.invoice.companyId}</InputLabel>
                  <Select value={modalData.companyId} label={strings.invoice.companyId} name='companyId' onChange={handleCompanySelectChange} >
                    {companies?.map(o => {
                      return <MenuItem value={o.id}>{o.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField type='text' required label={strings.invoice.customerName} name='customerName' fullWidth value={modalData.customerName} onChange={handleChange} />
              </Grid>

              <Grid item xs={7}>
                <TextField type='text' required label={strings.invoice.customerAddress} name='customerAddress' fullWidth value={modalData.customerAddress} onChange={handleChange} />
              </Grid>

              <Grid item xs={5}>
                <TextField type='text' required label={strings.invoice.postalInfo} name='postalInfo' fullWidth value={modalData.postalInfo} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='text' required label={strings.invoice.customerNumber} name='customerNumber' fullWidth value={modalData.customerNumber} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='text' required label={strings.invoice.uidNumber} name='uidNumber' fullWidth value={modalData.uidNumber} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='text' required label={strings.invoice.rentalAgreementNumber} name='rentalAgreementNumber' fullWidth value={modalData.rentalAgreementNumber} onChange={handleChange} />
              </Grid>

              <Grid item xs={8}>
                <TextField type='text' required label={strings.invoice.scooterName} name='scooterName' fullWidth value={modalData.scooterName} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='text' required label={strings.invoice.scooterPosition} name='scooterPosition' fullWidth value={modalData.scooterPosition} onChange={handleChange} />
              </Grid>

              <Grid item xs={8}>
                <TextField type='text' required label={strings.invoice.serviceName} name='serviceName' fullWidth value={modalData.serviceName} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='text' required label={strings.invoice.servicePosition} name='servicePosition' fullWidth value={modalData.servicePosition} onChange={handleChange} />
              </Grid>

              <Grid item xs={12}>
                <TextField type='text' rows={2} multiline label={strings.invoice.additionalDescription} name='additionalDescription' fullWidth value={modalData.additionalDescription} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.scooterGrossPrice} name='scooterGrossPrice' fullWidth value={modalData.scooterGrossPrice} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.serviceGrossPrice} name='serviceGrossPrice' fullWidth value={modalData.serviceGrossPrice} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.scooterNetPrice} name='scooterNetPrice' fullWidth value={modalData.scooterNetPrice} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.scooterUst} name='scooterUst' fullWidth value={modalData.scooterUst} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.serviceNetPrice} name='serviceNetPrice' fullWidth value={modalData.serviceNetPrice} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.serviceUst} name='serviceUst' fullWidth value={modalData.serviceUst} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.net} name='net' fullWidth value={modalData.net} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.ust} name='ust' fullWidth value={modalData.ust} onChange={handleChange} />
              </Grid>

              <Grid item xs={4}>
                <TextField type='number' required label={strings.invoice.gross} name='gross' fullWidth value={modalData.gross} onChange={handleChange} />
              </Grid>

            </Grid>

            <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

          </form>
        </Card>
      </Modal>

      <Snackbar open={successSnackbar.open} autoHideDuration={6000} onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} >
        <Alert onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} severity='success' sx={{ width: '100%' }}>
          {successSnackbar.message}
        </Alert>
      </Snackbar>

      <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
        <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
          {errorSnackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={detailModal} onClose={() => setDetailModal(false)} PaperProps={{ style: detailStyle }}  >
        <CompaniesDetail company={detailData} onClose={() => setDetailModal(false)} />
      </Dialog>

    </div>
  );
};
