import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Divider, IconButton, Paper, Popover, Stack, TextField, Typography, styled } from "@mui/material";
import { DateCalendar, PickersCalendarHeaderProps, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { ChangeEvent, ReactNode, VoidFunctionComponent, useRef, useState } from "react";

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    isSelected: boolean;
}

interface DateTimeRangePickerProps {

    onDateTimeChanged: (startDateTime: Dayjs | undefined, endDateTime: Dayjs | undefined) => void;
    pickersDayColor?: (date: Dayjs) => any;
    onOpen?: () => void;
    onClose?: () => void;
    onMonthChanged?: (month: Dayjs) => void;
    value: { start: Dayjs | undefined, end: Dayjs | undefined };
    disabled?: boolean;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    children?: ReactNode;
}


export default function DateTimeRangePicker(props: DateTimeRangePickerProps) {

    const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();

    const ref = useRef();

    function handlePopoverOpen() {

        setAnchorEl(ref.current);
        if (props.onOpen !== undefined) props.onOpen();
    }

    function handlePopoverClose() {

        setAnchorEl(undefined);
        if (props.onClose !== undefined) props.onClose();
    }

    function handleMonthChange(month: Dayjs) {

        setCurrentMonth(month);
        if (props.onMonthChanged !== undefined) props.onMonthChanged(month);
    }

    function calendarHeaderLeft(props: PickersCalendarHeaderProps<Dayjs>) {

        return (
            <div style={{ display: "flex", alignItems: "center", padding: "20px 20px 5px 20px" }}>
                <IconButton onClick={() => handleMonthChange(currentMonth.add(-1, 'month'))}>
                    <KeyboardArrowLeft style={{ flex: "0 0 auto" }} />
                </IconButton>
                <Typography sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }} >{props.currentMonth.locale('de').format("MMMM YYYY")}</Typography>
            </div>
        );
    }

    function calendarHeaderRight(props: PickersCalendarHeaderProps<Dayjs>) {

        return (
            <div style={{ display: "flex", alignItems: "center", padding: "20px 20px 5px 20px" }}>
                <Typography sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }} >{props.currentMonth.locale('de').format("MMMM YYYY")}</Typography>
                <IconButton onClick={() => handleMonthChange(currentMonth.add(1, 'month'))}>
                    <KeyboardArrowRight style={{ flex: "0 0 auto" }} />
                </IconButton>
            </div>
        );
    }

    function handleDateSelect(day: Dayjs) {

        if (props.value.start !== undefined && props.value.end === undefined) {
            props.onDateTimeChanged(props.value.start, day);
        } else {
            props.onDateTimeChanged(day, undefined);
        }

    }

    function handleStartTimeChange(e: ChangeEvent<HTMLInputElement>) {

        if (props.value.start === undefined) return;

        const hours = e.target.value.split(':')[0];
        const minutes = e.target.value.split(':')[1];

        const newDateTime = props.value.start.set('hours', Number(hours)).set('minutes', Number(minutes));

        props.onDateTimeChanged(newDateTime, props.value.end);
    }

    function handleResetDate() {

        props.onDateTimeChanged(undefined, undefined);
    }

    function handleEndTimeChange(e: ChangeEvent<HTMLInputElement>) {

        if (props.value.end === undefined) return;

        const hours = e.target.value.split(':')[0];
        const minutes = e.target.value.split(':')[1];

        const newDateTime = props.value.end.set('hours', Number(hours)).set('minutes', Number(minutes));

        props.onDateTimeChanged(props.value.start, newDateTime);
    }

    function isInRange(day: Dayjs) {

        return props.value.start !== undefined && props.value.end !== undefined && day >= props.value.start && day <= props.value.end;
    }

    function calendarDay(pickerProps: PickersDayProps<Dayjs>) {

        return (
            <CustomPickersDay
                {...pickerProps}
                sx={{ px: 2.3, backgroundColor: (props.pickersDayColor !== undefined) ? props.pickersDayColor(pickerProps.day) : null }}
                disableMargin
                selected={false}
                isSelected={isInRange(pickerProps.day)}
                onClick={() => handleDateSelect(pickerProps.day)}
                disabled={(props.value.start !== undefined && props.value.end === undefined && pickerProps.day < props.value.start) || (props.minDate !== undefined && pickerProps.day < props.minDate) || (props.maxDate !== undefined && pickerProps.day > props.maxDate)}
            />
        );
    }

    const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
    })<CustomPickerDayProps>(({ theme, isSelected, day }) => ({
        ...(isSelected && {
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
            },
        }),
        ...((day.day() === 0 || day.isSame(props.value.start)) && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...((day.day() === 6 || day.isSame(props.value.end)) && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
    })) as React.ComponentType<CustomPickerDayProps>;


    return (

        <div>

            <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center" }} >
                <TextField disabled={props.disabled} required inputRef={ref} fullWidth InputProps={{ readOnly: true }} value={props.value.start?.format("DD.MM.YYYY")} placeholder="DD.MM.YYYY" onClick={handlePopoverOpen} />
                <TextField disabled={props.value.start === undefined} required sx={{ width: "50%" }} fullWidth type="time" value={props.value.start?.format("HH:mm")} onChange={handleStartTimeChange} />
                <Typography variant="h5" >-</Typography>
                <TextField disabled={props.disabled} required fullWidth InputProps={{ readOnly: true }} value={props.value.end?.format("DD.MM.YYYY")} placeholder="DD.MM.YYYY" onClick={handlePopoverOpen} />
                <TextField disabled={props.value.end === undefined} required sx={{ width: "50%" }} fullWidth type="time" value={props.value.end?.format("HH:mm")} onChange={handleEndTimeChange} />
            </Stack>


            <Popover open={anchorEl !== undefined} anchorEl={anchorEl} onClose={handlePopoverClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Paper>
                    <Stack direction="row" >
                        <DateCalendar disableHighlightToday value={currentMonth} slots={{ calendarHeader: calendarHeaderLeft, day: calendarDay }} />
                        <Divider orientation="vertical" flexItem />
                        <DateCalendar disableHighlightToday value={currentMonth.add(1, 'month')} slots={{ calendarHeader: calendarHeaderRight, day: calendarDay }} />
                        {props.children}
                    </Stack>
                    <Stack direction="row" style={{ justifyContent: "right" }}>
                        <Button onClick={handleResetDate}>reset</Button>
                        <Button onClick={handlePopoverClose}>ok</Button>
                    </Stack>
                </Paper>
            </Popover>
        </div>
    );

}