import { Container } from '@mui/material';

export default function AccessDeniedPage() {

    return (
        <div>
            <Container component="main" maxWidth="xs">
                <img src='https://i.pinimg.com/originals/f8/64/43/f8644304ae1fe29b4520fe8c58c201a8.jpg' />

                <b style={{ display: "flex", justifyContent: "center" }} >Go back where you came from!</b>
            </Container>
        </div>
    );
}