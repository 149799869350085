import Region from "./Region";
import Role from "./Role";
import 'reflect-metadata'

@Reflect.metadata("name", "Driver")
export default class Driver {
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", String)
    name!: string;
    @Reflect.metadata("type", String)
    email!: string;
    @Reflect.metadata("type", Role)
    roles!: Role[];
    @Reflect.metadata("type", String)
    entityStatus!: string;
    @Reflect.metadata("type", Number)
    companyId!: number;
    @Reflect.metadata("type", Object)
    region!: Region;
}