
export enum Roles {
    ROLE_SUPERADMIN = 1,
    ROLE_ADMIN,
    ROLE_ASSISTANT,
    ROLE_SERVICEMAN,
    ROLE_COMPANY,
    ROLE_DISPATCHER,
    ROLE_DRIVER,
    ROLE_FEEDER,
    ROLE_EMPLOYEE,
    ROLE_SCOOTER
}