import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from '../../assets/Jumug-Logo.png';
import NetService from '../../services/NetService';
import { useSearchParams } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import { getLocalizedStrings } from '../../App';

export default function ResetPassword() {

    const [confirmVisibility, setConfirmVisibility] = React.useState<boolean>(false);
    const [errorVisibility, setErrorVisibility] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const strings = getLocalizedStrings();
    const [queryParameters] = useSearchParams();

    React.useEffect(() => {

        if (queryParameters.get("token") === null) {
            window.location.href = "/login";
        }

    }, []);

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const password = data.get("password")?.toString() ?? ""
        const token = queryParameters.get("token");
        if (token === null) return;

        NetService.Post(`/auth/reset-password/${token}`, {
            "newPassword": password
        })
            .then(o => {
                if (o.status === 200) {
                    setConfirmVisibility(true);
                    setErrorVisibility(false);
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 3000);
                } else {
                    setErrorMessage(o.data.message);
                    setErrorVisibility(true);
                    setConfirmVisibility(false);
                }

            });

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src={Logo} />
                <Typography component="h1" variant="h5">
                    {strings.resetPassword.header}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={strings.resetPassword.newPassword}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {strings.resetPassword.acceptChange}
                    </Button>
                </Box>

                {confirmVisibility &&
                    <Paper style={{ padding: "10px", display: "flex", justifyContent: "center", transition: "all 0.3s ease-in" }} >
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                <CheckCircle color='success' />
                            </Grid>

                            <Grid item xs={10}>
                                {strings.resetPassword.resetSuccessful}
                            </Grid>
                        </Grid>
                    </Paper>
                }

                {errorVisibility &&
                    <Paper style={{ padding: "10px", display: "flex", justifyContent: "center", transition: "all 0.3s ease-in" }} >
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                <Error color='error' />
                            </Grid>

                            <Grid item xs={10}>
                                {errorMessage}
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </Box>
        </Container>
    );
}