import React, { FormEvent, useEffect, useState } from 'react';
import NetService from '../../services/NetService';
import CrudTable from '../../components/CrudTable';
import { Alert, Button, Card, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Snackbar, TextField } from '@mui/material';
import SnackbarData from '../../common/models/SnackbarData'
import Driver from '../../common/models/Driver';
import CompaniesDetail from '../Companies/CompaniesDetail';
import Company from '../../common/models/Company';
import { MRT_ColumnDef } from 'material-react-table';
import { Info } from '@mui/icons-material';
import { Roles } from '../../common/enums/Roles';
import Region from '../../common/models/Region';
import Scooter, { ScooterReservation } from '../../common/models/Scooter';
import dayjs from 'dayjs';
import { EntityStatus } from '../../common/enums/EntityStatus';
import AuthService from '../../services/AuthService';
import { getLocalizedStrings } from '../../App';
import Reservation from '../../common/models/Reservation';


class CreateDriver {
    name!: string;
    email!: string;
    password!: string;
    role!: string;
    companyId!: number;
    regionId!: number;
}

export default function DriverPage() {

    const [scooters, setScooters] = useState<Scooter[]>([]);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [regions, setRegions] = useState<Region[]>([]);
    const [companyDetailModal, setCompanyDetailModal] = useState<boolean>(false);
    const [companyDetailData, setCompanyDetailData] = useState<Company>(new Company());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [createData, setCreateData] = useState<CreateDriver>(new CreateDriver());
    const [successSnackbar, setSuccessSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
    const [assignDriverModal, setAssignDriverModal] = useState<boolean>(false);
    const [assignDriverScooter, setAssignDriverScooter] = useState<Scooter>();
    const [assignDriverReservation, setAssignDriverReservation] = useState<ScooterReservation>();
    const [assignDriverDriver, setAssignDriverDriver] = useState<Driver>();
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const strings = getLocalizedStrings();

    const detailStyle: React.CSSProperties = {
        height: '100vh',
        width: '40vw',
        maxWidth: 'none',
        margin: 0,
        backgroundColor: '#000f',
        borderRadius: 0,
        position: 'fixed',
        top: 0,
        right: companyDetailModal ? 0 : '-40vw',
        transition: 'right 0.3s ease-out',
        outline: 'none',
    };


    useEffect(() => {

        NetService.AGet("/companies")
            .then(resp => {
                if (resp.status === 200) setCompanies(resp.data.data.Companies);
                setCreateData({ ...createData, companyId: Number(AuthService.GetCompanyId()) });
            });

        NetService.AGet("/regions")
            .then(resp => setRegions(resp.data.data.Regions));

        NetService.AGet(AuthService.IsAdmin() ? "/drivers" : `/drivers?companyId=${AuthService.GetCompanyId()}`)
            .then(resp => setDrivers(resp.data.data.drivers));

        NetService.AGet("/scooters")
            .then(resp => setScooters(resp.data.data.Scooters));

        NetService.AGet(AuthService.IsAdmin() ? `/reservations` : `/reservations?companyId=${AuthService.GetCompanyId()}`)
            .then(resp => setReservations(resp.data.data.reservations));


    }, []);

    function handleCreateSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APost("/auth/employees/register", createData)
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
    }

    function handleCreateChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        // if (value.trim() === '') return;

        (child !== undefined) ? (
            setCreateData({
                ...createData,
                [parent]: {
                    ...createData[parent as keyof typeof createData] as {},
                    [child]: value
                }
            })
        ) : (
            setCreateData({ ...createData, [parent]: value })
        )
    }

    const overriteColumns = React.useMemo<MRT_ColumnDef<Driver>[]>(
        () => [
            {
                accessorFn: (o) => (
                    <IconButton disabled={o.companyId === null || companies === undefined} onClick={() => {

                        setCompanyDetailData(companies.find(f => f.companyId === o.companyId) ?? new Company());
                        setCompanyDetailModal(true);
                    }}>
                        <Info />
                    </IconButton>
                ),
                accessorKey: "companyId",
                header: AuthService.IsAdmin() ? strings.driver.company : ""
            },
            {
                accessorFn: (o) => (o.region !== null) ? o.region.regionName : "",
                accessorKey: "region",
                header: strings.driver.region
            }
        ],
        []
    );

    function handleDriverAssignSubmit(e: FormEvent) {
        e.preventDefault();

        NetService.APut("/reservations/assignee-driver", { reservationId: assignDriverReservation?.id, scooterId: assignDriverScooter?.id, driverId: assignDriverDriver?.id })
            .then(resp => {
                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    setErrorSnackbar({ open: true, message: resp.data.message });
                }
            });
    }

    return (

        <div>
            <CrudTable enableDelete overriteColumns={overriteColumns} endpoint={`/drivers?` + (!AuthService.IsAdmin() && `companyId=${AuthService.GetCompanyId()}&`) + `entityStatus=${EntityStatus[EntityStatus.ACTIVE]}`} dataType={Driver} dataInstance={new Driver()} renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.create}</Button>
                        <Button style={{ margin: "0px 10px" }} variant='contained' color='info' onClick={() => setAssignDriverModal(true)} >{strings.driver.assignDriver}</Button>
                    </div>
                );
            }} columns={[]} data={[]} />

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.driver.registration}</a>

                    <form onSubmit={handleCreateSubmit} >
                        <Grid container spacing={2} style={{ padding: "15px 0px" }} >
                            <Grid item xs={5}>
                                <TextField type='text' required label={strings.driver.name} name='name' fullWidth value={createData.name} onChange={handleCreateChange} />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField type='email' required label={strings.driver.email} name='email' fullWidth value={createData.email} onChange={handleCreateChange} />
                            </Grid>

                            <Grid item xs={7}>
                                <TextField type='password' required label={strings.company.password} name='password' fullWidth value={createData.password} onChange={handleCreateChange} />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.driver.role}</InputLabel>
                                    <Select label={strings.driver.role} name='role' onChange={handleCreateChange} >
                                        {Object.keys(Roles).filter(f => !isNaN(Number(f)) && Number(f) >= 6 && Number(f) <= 9).map(o => { // Who tf created this bs isNaN function??? Makes no sense, fucking ts
                                            return <MenuItem value={Roles[Number(o)]}>{Roles[Number(o)]}</MenuItem> // and btw, fuck enums in Ts! Whoever coded them clearly wanted to drive people crazy...
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {companies.length !== 0 &&
                                <Grid item xs={6}>
                                    <FormControl required fullWidth>
                                        <InputLabel>{strings.driver.company}</InputLabel>
                                        <Select label={strings.driver.company} name='companyId' onChange={handleCreateChange} >
                                            {companies?.map(o => {
                                                return <MenuItem value={o.id}>{o.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.driver.region}</InputLabel>
                                    <Select label={strings.driver.region} name='regionId' onChange={handleCreateChange} >
                                        {regions.map(o => {
                                            return <MenuItem value={o.id}>{o.regionName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={assignDriverModal} onClose={() => setAssignDriverModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.scooter.assignDriver}</a>

                    <form onSubmit={handleDriverAssignSubmit} >
                        <Grid container spacing={2} >

                            <Grid item xs={6} >
                                <FormControl required fullWidth>
                                    <InputLabel>{strings.reservationName}</InputLabel>
                                    <Select label={strings.reservationName} onChange={(e) => setAssignDriverReservation(assignDriverScooter?.scooterReservations.find(o => o.id === e.target.value))} >
                                        {reservations.filter(f => f.endDateTime >= dayjs()).map(o => {
                                            return <MenuItem value={o.id}>{o.company.name} | {dayjs(o.startDateTime).format("DD.MM.YYYY hh:mm")} - {dayjs(o.endDateTime).format("DD.MM.YYYY hh:mm")}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} >
                                <FormControl disabled={assignDriverReservation === undefined} required fullWidth>
                                    <InputLabel>{strings.scooterName}</InputLabel>
                                    <Select label={strings.scooterName} onChange={(e) => setAssignDriverScooter(scooters.find(o => o.id === e.target.value))} >
                                        {scooters.map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} >
                                <FormControl disabled={assignDriverScooter === undefined || assignDriverReservation === undefined} required fullWidth>
                                    <InputLabel>{strings.driverName}</InputLabel>
                                    <Select label={strings.driverName} onChange={(e) => setAssignDriverDriver(drivers.find(o => o.id === e.target.value))} >
                                        {drivers.filter(f => f.region === null || f.region.id === assignDriverScooter?.region.id).map(o => {
                                            return <MenuItem value={o.id}>{o.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={successSnackbar.open} autoHideDuration={6000} onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} >
                <Alert onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} severity='success' sx={{ width: '100%' }}>
                    {successSnackbar.message}
                </Alert>
            </Snackbar>

            <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
                <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
                    {errorSnackbar.message}
                </Alert>
            </Snackbar>

            <Dialog open={companyDetailModal} onClose={() => setCompanyDetailModal(false)} PaperProps={{ style: detailStyle }}  >
                <CompaniesDetail company={companyDetailData} onClose={() => setCompanyDetailModal(false)} />
            </Dialog>

        </div>
    );
};
