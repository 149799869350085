import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MapOverlay from './MapOverlay';

export default function MapPage() {

    return (
        <MapContainer attributionControl={false} style={{ height: "100vh", width: "100vw" }} center={[48.811946, 11.489069]} zoom={5} scrollWheelZoom >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MapOverlay />

        </MapContainer >
    );
};
