import React, { FormEvent, useEffect, useState } from 'react';
import NetService from '../../services/NetService';
import Company from '../../common/models/Company';
import CrudTable from '../../components/CrudTable';
import { Alert, Box, Button, Card, Checkbox, Dialog, FormControlLabel, Grid, MenuItem, Modal, Select, Snackbar, TextField } from '@mui/material';
import CompanyRegister from '../../common/models/CompanyRegister';
import CompaniesDetail from './CompaniesDetail';
import SnackbarData from '../../common/models/SnackbarData'
import { MRT_ColumnDef } from 'material-react-table';
import { EntityStatus } from '../../common/enums/EntityStatus';
import { getLocalizedStrings } from '../../App';


export default function CompaniesPage() {

  const [data, setData] = useState<Company[]>([]);
  const [modalData, setModalData] = useState<CompanyRegister>(new CompanyRegister());
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [successSnackbar, setSuccessSnackbar] = useState<SnackbarData>(new SnackbarData());
  const [errorSnackbar, setErrorSnackbar] = useState<SnackbarData>(new SnackbarData());
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [detailData, setDetailData] = useState<Company>(new Company());
  const strings = getLocalizedStrings();


  const detailStyle: React.CSSProperties = {
    height: '100vh',
    width: '40vw',
    maxWidth: 'none',
    margin: 0,
    backgroundColor: '#000f',
    borderRadius: 0,
    position: 'fixed',
    top: 0,
    right: detailModal ? 0 : '-40vw',
    transition: 'right 0.3s ease-out',
    outline: 'none',
  };



  useEffect(() => {

    NetService.AGet("/companies")
      .then(resp => {
        NetService.AGet("/companies/pending")
          .then(final => {
            let arr: Company[] = resp.data.data.Companies;
            setData(arr.concat(final.data.data.Companies));
          })
      });


  }, []);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const request = NetService.APost("/auth/companies/register", modalData);
    if (request !== undefined) {
      request.then(resp => {
        if (resp.status === 201) {
          window.location.reload();
        } else {
          setErrorSnackbar({ message: resp.data.message, open: true });
        }
      });
    }
  }

  function handleVerify() {

    NetService.APost(`/companies/${selectedCompany?.id}/verify`, null)
      .then(resp => {
        if (resp.status === 200) {
          window.location.reload();
        } else {

        }
      })

  }

  function handleChange(e: { target: { name: any; value: any; }; }) {

    const { name, value } = e.target;
    const [parent, child] = name.split(".");

    if (typeof value === 'string' && value.trim() === '') return;

    (child !== undefined) ? (
      setModalData({
        ...modalData,
        [parent]: {
          ...modalData[parent as keyof typeof modalData] as {},
          [child]: value
        }
      })
    ) : (
      setModalData({ ...modalData, [parent]: value })
    )
  }

  function handleCheckox(e: { target: { name: any; value: any; }; }) {

    const { name, value } = e.target;
    const [parent, child] = name.split(".");

    setModalData({ ...modalData, [parent]: !modalData.oneManCompany });
  }

  function handleDetailClick(value: Company) {
    setDetailData(value);
    setDetailModal(true);
  }


  const overriteColumns = React.useMemo<MRT_ColumnDef<Company>[]>(
    () => [
      {
        accessorKey: "entityStatus",
        header: strings.company.entityStatus,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue<string>() !== EntityStatus[EntityStatus.ACTIVE]
                  ? theme.palette.warning.dark
                  : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue<string>()}
          </Box>
        )
      },
      {
        accessorKey: "companyId",
        header: ""
      }
    ],
    []
  );


  return (

    <div>
      <CrudTable overriteColumns={overriteColumns} onClick={handleDetailClick} cancelRequest endpoint='/companies' dataType={Company} dataInstance={new Company()} renderTopToolbarCustomActions={({ }) => {
        return (
          <div>
            <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.company.create}</Button>

            <Select style={{ margin: "0px 5px 0px 50px", height: "40px" }} onChange={(e) => setSelectedCompany(data.find(o => o.id === e.target.value))} >
              {data.filter(o => o.entityStatus === EntityStatus[EntityStatus.PENDING]).map(o => {
                return <MenuItem value={o.id}>{o.name}</MenuItem>
              })}
            </Select>
            <Button variant='contained' color='info' onClick={handleVerify} >Verifizieren</Button>
          </div>
        );
      }} columns={[]} data={data} />

      <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
        <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

          <a style={{ fontSize: 30 }} >{strings.company.registration}</a>

          <form onSubmit={handleSubmit} >
            <Grid container spacing={2} style={{ padding: "15px 0px" }} >
              <Grid item xs={6}>
                <TextField type='text' required label={strings.company.name} name='name' fullWidth value={modalData.name} onChange={handleChange} />
              </Grid>

              <Grid item xs={6}>
                <TextField required type='email' label={strings.company.email} fullWidth name='email' value={modalData.email} onChange={handleChange} />
              </Grid>

              <Grid item xs={5}>
                <TextField type='password' required label={strings.company.password} fullWidth name='password' value={modalData.password} onChange={handleChange} />
              </Grid>
              <Grid item xs={7}>
                <TextField type='text' required label={strings.company.address} fullWidth name='address' value={modalData.address} onChange={handleChange} />
              </Grid>

              <Grid item xs={6}>
                <TextField type='email' required label={strings.company.contactPersonEmail} fullWidth name='contactPersonEmail' value={modalData.contactPersonEmail} onChange={handleChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField type='tel' required label={strings.company.contactPersonPhoneNumber} fullWidth name='contactPersonPhoneNumber' value={modalData.contactPersonPhoneNumber} onChange={handleChange} />
              </Grid>

              <Grid item xs={6}>
                <TextField type='text' inputProps={{ pattern: '^\d{6-11}$' }} required label={strings.company.gisaNumber} fullWidth name='gisaNumber' value={modalData.gisaNumber} onChange={handleChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField type='text' inputProps={{ pattern: '^ATU\d{6-10}$' }} required label={strings.company.uid} fullWidth name='uid' value={modalData.uid} onChange={handleChange} />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel style={{ display: "flex", justifyContent: "center" }} control={<Checkbox name='oneManCompany' value={modalData.oneManCompany} onChange={handleCheckox} />} label={strings.company.oneManCompany} />
              </Grid>
            </Grid>

            <Button style={{ margin: "5px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.done}</Button>

          </form>
        </Card>
      </Modal>

      <Snackbar open={successSnackbar.open} autoHideDuration={6000} onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} >
        <Alert onClose={() => setSuccessSnackbar({ ...successSnackbar, open: false })} severity='success' sx={{ width: '100%' }}>
          {successSnackbar.message}
        </Alert>
      </Snackbar>

      <Snackbar open={errorSnackbar.open} autoHideDuration={6000} onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} >
        <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })} severity='error' sx={{ width: '100%' }}>
          {errorSnackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={detailModal} onClose={() => setDetailModal(false)} PaperProps={{ style: detailStyle }}  >
        <CompaniesDetail company={detailData} onClose={() => setDetailModal(false)} />
      </Dialog>

    </div>
  );
};
