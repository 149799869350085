import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import AuthService from '../services/AuthService';
import { Divider } from '@mui/material';
import { useTheme } from './CustomThemeProdiver';
import { Circle, KeyboardArrowDown, Notifications } from '@mui/icons-material';
import { Roles } from '../common/enums/Roles';
import { getLocalizedStrings } from '../App';
import { useNavigate } from 'react-router-dom';


type MenuItemData = {
    name: string;
    url: string;
    roles: Roles[];
}

export default function NavigationBar() {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElMessages, setAnchorElMessages] = React.useState<null | HTMLElement>(null);
    const [anchorElConfig, setAnchorElConfig] = React.useState<null | HTMLElement>(null);
    const strings = getLocalizedStrings();
    const { theme, toggleTheme } = useTheme();
    const navigate = useNavigate();

    const pages: MenuItemData[] = [
        { name: strings.navigationBar.companies, url: "/companies", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN] },
        { name: strings.navigationBar.drivers, url: "/drivers", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN, Roles.ROLE_COMPANY] },
        { name: strings.navigationBar.scooters, url: "/scooters", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN] },
        { name: strings.navigationBar.reservations, url: "/reservations", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN, Roles.ROLE_COMPANY] },
        { name: strings.navigationBar.billing, url: "/billing", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN] },
        { name: strings.navigationBar.maps, url: "/maps", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN, Roles.ROLE_COMPANY] },
        { name: strings.navigationBar.regions, url: "/regions", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN] },
        { name: strings.navigationBar.hubs, url: "/hubs", roles: [Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN] }
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleRouting = (route: string) => {
        navigate(route);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => handleRouting("/")}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.25rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: "pointer"
                        }}
                    >
                        {strings.logoName}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.filter(f => AuthService.HasAnyRole(f.roles)).map((page) => (
                                <MenuItem onClick={() => handleRouting(page.url)}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        onClick={() => handleRouting("/")}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        {strings.logoName}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.filter(f => AuthService.HasAnyRole(f.roles)).map((page) => (
                            <Button onClick={() => handleRouting(page.url)} sx={{ my: 2, color: 'white', display: 'block' }} >
                                {page.name}
                            </Button>
                        ))}

                        {AuthService.HasAnyRole([Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN]) &&
                            <div>
                                <Button onClick={(e) => setAnchorElConfig(e.currentTarget)} sx={{ my: 2, color: 'white', display: 'flex' }}>
                                    <KeyboardArrowDown />
                                    {strings.navigationBar.configuration}
                                </Button>
                                <Menu keepMounted anchorEl={anchorElConfig} open={Boolean(anchorElConfig)} onClose={() => setAnchorElConfig(null)} >
                                    <div style={{ padding: "5px" }}>
                                        <MenuItem onClick={() => handleRouting("/config")}>Global</MenuItem>
                                        <MenuItem onClick={() => handleRouting("/config/scooter")}>Scooter</MenuItem>
                                    </div>
                                </Menu>
                            </div>}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <div style={{ padding: "5px" }} >
                                <div>
                                    Theme:
                                    <IconButton onClick={() => toggleTheme(true)} >
                                        <Circle htmlColor='#111' />
                                    </IconButton>
                                    <IconButton onClick={() => toggleTheme(false)}>
                                        <Circle htmlColor='#ddd' />
                                    </IconButton>
                                </div>

                                <Divider />

                                <MenuItem onClick={() => handleRouting("/profile")}>{strings.profile}</MenuItem>
                                <MenuItem onClick={() => AuthService.Logout()} >{strings.logout}</MenuItem>
                            </div>
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 0, margin: "0px 0px 0px 20px" }}>
                        <Tooltip title="Messages">
                            <IconButton onClick={() => { throw new Error("NOT IMPLEMENTED") }} sx={{ p: 0 }}>
                                <Notifications />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElMessages}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElMessages)}
                            onClose={() => { throw new Error("NOT IMPLEMENTED") }}
                        >
                            <div style={{ padding: "5px" }} >


                            </div>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}