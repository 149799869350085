import NetService from "./NetService";
import { Outlet } from "react-router-dom";
import * as jose from 'jose';
import Authority from "../common/models/Authority";
import { Roles } from "../common/enums/Roles";
import dayjs from "dayjs";

export type AuthorizationResponse = {
    successful: boolean;
    message: string;
}

export default class AuthService extends NetService {

    public static async Authorize(email: string, password: string) {

        const resp = await this.Post("/auth/login", {
            "email": email,
            "password": password
        });

        if (resp.data.statusCode === 200) {
            this.SetToken(resp.data.data.token);
            this.SetLoggedIn(true);
            this.SetRoles(jose.decodeJwt(resp.data.data.token).roles as Authority[]);
            this.SetCompanyId(jose.decodeJwt(resp.data.data.token).id as string);
            return {
                successful: true,
                message: resp.data.message
            } as AuthorizationResponse;
        } else {
            return {
                successful: false,
                message: resp.data.message
            } as AuthorizationResponse;
        }
    }

    public static Logout() {

        this.SetLoggedIn(false);
        this.SetToken("");

        window.location.href = "/login";
    }

    public static PrivateRoute({ roles }: { roles?: Roles[] }) {

        const token = AuthService.GetToken();
        let expiration;
        if (token !== "") {
            expiration = dayjs.unix(jose.decodeJwt(token).exp ?? 0);
        } else {
            expiration = dayjs().add(1, "year");
        }


        if (AuthService.IsLoggedIn() === false || expiration < dayjs()) {
            window.location.href = "/login";
            return <></>
        }
        else if (roles !== undefined && AuthService.HasAnyRole(roles) === false) {
            window.location.href = "/accessDenied";
            return <></>
        }
        else return <Outlet />
    }

    public static GetToken() {

        const token = sessionStorage.getItem("Token");
        return (token !== null) ? token : "";
    }

    private static SetToken(token: string) {

        sessionStorage.setItem("Token", token);
    }

    public static IsLoggedIn() {

        return sessionStorage.getItem("IsLoggedIn") === "true";
    }

    private static SetLoggedIn(value: boolean) {

        sessionStorage.setItem("IsLoggedIn", String(value));
    }

    private static SetRoles(value: Authority[]) {

        sessionStorage.setItem("Roles", JSON.stringify(value));
    }

    public static GetRoles(): Authority[] {

        return JSON.parse(sessionStorage.getItem("Roles") ?? "[]");
        
    }

    public static HasRole(value: Roles) {

        return this.GetRoles().some(o => o.authority === Roles[value]);
    }

    public static HasAnyRole(values: Roles[]) {

        return this.GetRoles().some(o => values.includes(Roles[o.authority as keyof typeof Roles]));
    }

    private static SetCompanyId(value: string) {

        sessionStorage.setItem("companyId", value);
    }

    public static GetCompanyId() {

        return sessionStorage.getItem("companyId");
    }

    public static IsAdmin() {

        return this.HasAnyRole([Roles.ROLE_ADMIN, Roles.ROLE_SUPERADMIN]);
    }
}