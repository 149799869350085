import Scooter from "./Scooter";

@Reflect.metadata("name", "ScooterControls")
export default class ScooterControls {
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", Number)
    tokensVersion!: number;
    @Reflect.metadata("type", Boolean)
    stopVehicle: boolean = false;
    @Reflect.metadata("type", Object)
    scooter!: Scooter;
    scooterId!: number;
    @Reflect.metadata("type", Boolean)
    emergencyOpening: boolean = false;
    @Reflect.metadata("type", Number)
    parkBreakCurrent: number = 0;
    @Reflect.metadata("type", Number)
    firmwareVersion!: number;
    @Reflect.metadata("type", String)
    firmwareDownloadLink!: string;
    @Reflect.metadata("type", Number)
    twoBatteryMode!: number;
}
